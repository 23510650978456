import * as yup from 'yup';

const formikSchema = {
  initialValues: {
    password: '',
    confirmPassword: '',
  },
  validationSchema: yup.object({
    password: yup.string().min(8).required('Password is required'),
    confirmPassword: yup.string().required(),
  }),
};

export default formikSchema;
