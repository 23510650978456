import styled from 'styled-components';

const PageContainer = styled.div`
  height: ${({ height }) => height || '88vh'};
  display: grid;
  align-items: ${({ align }) => align || 'start'};
  grid-template-rows: ${({ gridStyling }) => gridStyling || 'auto 1fr'};
  grid-row-gap: ${({ gap }) => gap || '1.5rem'};
  & .bx--tab-content {
    height: 100%;
    padding: 0;
  }
`;

export default PageContainer;
