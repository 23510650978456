import React, { createContext, useState } from 'react';

const RightSideViewContext = createContext();

const RightSideViewProvider = ({ children }) => {
  const [isRightSideViewOpen, setIsRightSideViewOpen] = useState(false);
  return (
    <RightSideViewContext.Provider
      value={{
        isRightSideViewOpen,
        setIsRightSideViewOpen,
      }}
    >
      {children}
    </RightSideViewContext.Provider>
  );
};

export { RightSideViewContext, RightSideViewProvider };
