import styled from 'styled-components';
import { PrimaryButton } from '../../Buttons';
import Download from '@carbon/icons-react/lib/download/20';
import { Link as Linkdom } from 'react-router-dom';

const ActionButton = styled(PrimaryButton)`
  border-radius: 0;
  height: 32px;
  display: flex;
  width: auto;
  min-width: 100px;
  justify-content: center;
  padding: 0 20px;
  & p {
    font-weight: bold;
    font-size: 0.85rem;
  }
  margin-left: 5px;
`;

const DownloadIcon = styled(Download)`
  & path {
    fill: ${({ theme }) => theme.colors.white};
  }
`;

const ActionRowContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Link = styled(Linkdom)`
  text-decoration: none;
`;

const SearchContainer = styled.div`
  width: 300px;
  margin: 0 10px;
`;

export {
  ActionRowContainer,
  ActionButton,
  DownloadIcon,
  Link,
  SearchContainer,
};
