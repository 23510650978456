import { differenceInSeconds, format } from 'date-fns';
import { mean, sum } from 'ramda';

const formatTimeFromSeconds = (
  duration,
  noSec,
  absolute = false,
  humanize = false,
) => {
  if (!duration) {
    return '-';
  }
  const formatDurationPart = (t) => {
    const length = `${t}`.length;
    const numberOfZeroes = length === 1 ? 1 : 0;
    const slice = Math.max(length, 2);
    const zeroString = [...Array(numberOfZeroes)].map(() => '0').join();
    return `${zeroString}${t}`.slice(-slice);
  };
  const dur = absolute ? Math.abs(duration) : duration;
  const hoursUnrounded = dur / 3600;
  const hours = Math.floor(hoursUnrounded);
  const minutesUnrounded = (hoursUnrounded - hours) * 60;
  const minutes = Math.floor(minutesUnrounded);
  const seconds = Math.round((minutesUnrounded - minutes) * 60);

  const daysWhole = Math.floor(hours / 24);
  const laggingHours = hours % (daysWhole * 24);
  const laggingMinutes = Math.round((hoursUnrounded - hours) * 60, 0);
  return humanize
    ? daysWhole
      ? `${daysWhole} days ${laggingHours} hrs`
      : hours >= 1
      ? `${hours} hrs ${laggingMinutes} mins`
      : `${minutes} mins`
    : `${formatDurationPart(hours)}:${formatDurationPart(
        seconds > 59 ? minutes + 1 : minutes,
      )}${noSec ? '' : `:${formatDurationPart(seconds > 59 ? 0 : seconds)}`}`;
};

const formatDateTime = (date, orgConfiguration) =>
  !date
    ? '-'
    : format(
        new Date(date),
        `${
          orgConfiguration && orgConfiguration.dateFormat
            ? orgConfiguration.dateFormat
            : 'yyyy-MM-dd'
        } ${
          orgConfiguration && orgConfiguration.timeFormat
            ? orgConfiguration.timeFormat
            : 'HH:mm'
        }`,
      );

const formatDashDateTime = (date) =>
  !date ? '-' : format(new Date(date), 'dd MMM HH:mm');

const formatDuration = (start, end, noSecs, getValue, humanize) => {
  if (!start || !end) {
    if (getValue) {
      return {
        valueInSeconds: null,
        format: '-',
      };
    }
    return '-';
  }
  const valueInSeconds = differenceInSeconds(new Date(end), new Date(start));
  const stringFormat = formatTimeFromSeconds(
    valueInSeconds,
    noSecs,
    false,
    humanize,
  );
  if (getValue) {
    return {
      valueInSeconds,
      format: stringFormat,
    };
  }
  return stringFormat;
};

const getAverageDuration = (startEnds, noSec) => {
  const durations = startEnds
    .filter((s) => s.start && s.end)
    .map(({ start, end }) =>
      differenceInSeconds(new Date(end), new Date(start)),
    );
  return durations.length > 0
    ? formatTimeFromSeconds(mean(durations), noSec)
    : '-';
};

const getTotalDuration = (startEnds, getValue, noSec) => {
  const durations = startEnds
    .filter((s) => s.start && s.end)
    .map(({ start, end }) =>
      differenceInSeconds(new Date(end), new Date(start)),
    );
  if (durations.length === 0) {
    if (getValue) {
      return {
        valueInSeconds: null,
        format: '-',
      };
    }
    return '-';
  }
  const stringFormat = formatTimeFromSeconds(sum(durations), noSec);
  if (getValue) {
    return {
      valueInSeconds: sum(durations),
      format: stringFormat,
    };
  }
  return stringFormat;
};

export {
  formatDateTime,
  formatDuration,
  getAverageDuration,
  getTotalDuration,
  formatDashDateTime,
  formatTimeFromSeconds,
};
