import axios from 'axios';
import camelcase from 'camelcase-keys';
import { localStorageKeys } from '../config';

const axiosInstance = axios.create({
  baseURL: 'https://rest.trackmatic.co.za/api/v2/',
  timeout: 30000,
  headers: {
    CustomerPortalAuthentication: `Bearer ${localStorage.getItem(
      localStorageKeys.idToken,
    )}`,
  },
  transformResponse: (data) => {
    return camelcase(JSON.parse(data), { deep: true });
  },
});

const setCustomerPortalAuthToken = (token) => {
  axiosInstance.defaults.headers.CustomerPortalAuthentication = token
    ? `Bearer ${token}`
    : null;
};

export { axiosInstance, setCustomerPortalAuthToken };
