const usEven = (num) => num % 2 === 0;

const encrypt = (sensitiveData) => {
  const length = sensitiveData.length;
  let maskedItemCount = Math.ceil(length / 2);
  const slicePoints = Math.floor(maskedItemCount / 2);
  usEven(length) && !usEven(maskedItemCount)
    ? (maskedItemCount += 1)
    : !usEven(length) && usEven(maskedItemCount) && (maskedItemCount -= 1);
  return (
    sensitiveData.slice(0, slicePoints) +
    '*'.repeat(maskedItemCount) +
    sensitiveData.slice(-slicePoints)
  );
};
export { encrypt };
