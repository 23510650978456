import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { routing } from '../config';
import { CustomerList } from '../modules';

const TMUser = () => {
  return (
    <Switch>
      <Route path={routing.customers.root} component={CustomerList} />
      <Route path="/" component={CustomerList} />
      <Redirect to="/" />
    </Switch>
  );
};

export default TMUser;
