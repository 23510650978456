import { getIn } from 'formik';
import { View20 } from '@carbon/icons-react';
import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { lighten } from 'polished';

const Label = styled.div`
  font-size: 0.75rem;
  margin-bottom: 5px;
`;

const InputField = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  border-bottom: 1px solid ${({ theme }) => theme.colors.mediumGrey};
  align-items: center;
`;

let PasswordFormField = ({ field, form, ...props }) => {
  const [type, setType] = useState('password');
  if (!field) {
    const inputComponent = (
      <InputField>
        <input
          type={type}
          className={props.className}
          value={props.value}
          onChange={props.onChange}
        />
        <div style={{ padding: '0 0.5rem' }}>
          <View20
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setType((prev) => (prev === 'password' ? 'text' : 'password'));
            }}
          />
        </div>
      </InputField>
    );
    return (
      <Fragment>
        {props.labelText && <Label>{props.labelText}</Label>}
        {inputComponent}
      </Fragment>
    );
  }
  const errorText =
    getIn(form.touched, field.name) && getIn(form.errors, field.name);
  const errorMessage = errorText
    ? errorText.replace(field.name, props.labelText)
    : null;
  const inputComponentFormik = (
    <InputField>
      <input
        type={type}
        id={field.name}
        invalid={!!errorText}
        invalidText={errorMessage}
        {...field}
        {...props}
      />
      <div style={{ padding: '0 0.5rem' }}>
        <View20
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setType((prev) => (prev === 'password' ? 'text' : 'password'));
          }}
        />
      </div>
    </InputField>
  );
  return (
    <Fragment>
      {props.labelText && <Label>{props.labelText}</Label>}
      {inputComponentFormik}
    </Fragment>
  );
};

PasswordFormField = styled(PasswordFormField)`
  height: 32px;
  border: none;
  width: 100%;
  padding: 0.5rem;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: ${({ theme }) => lighten(0.1, theme.colors.mediumGrey)};
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: ${({ theme }) => lighten(0.1, theme.colors.mediumGrey)};
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: ${({ theme }) => lighten(0.1, theme.colors.mediumGrey)};
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: ${({ theme }) => lighten(0.1, theme.colors.mediumGrey)};
  }
`;

export default PasswordFormField;
