import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { lighten } from 'polished';

const StyledLink = styled(Link)`
  font-size: 0.75rem;
  text-decoration: none;
  color: #393939;
  display: flex;
  align-items: center;
  justify-content: center;
  & a {
    width: 100%;
    height: 100%;
  }
`;

const PrimaryLink = styled(StyledLink)`
  border: none;
  width: ${({ width, fillWidth }) => (fillWidth ? '100%' : width || '180px')};
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  background: ${({ theme }) => theme.colors.primary};
  :hover {
    background: ${({ theme }) => lighten(0.1, theme.colors.primary)};
  }
  color: #fff;
  cursor: pointer;
`;

export { StyledLink as default, PrimaryLink };
