import React, { useContext, Fragment } from 'react';
import { LoginContext } from '../../context';
import { withRouter } from 'react-router-dom';
import {
  BreadCrumbsContainer,
  BreadCrumbLink,
  BreadCrumbNoLink,
  BreadCrumbsSeparator,
} from './styles';
import { startCase } from 'lodash';
import { entityTypes } from '../../config';

const BreadCrumbs = withRouter(({ location }) => {
  const { currentUser, currentEntity } = useContext(LoginContext);
  const splitPaths = location.pathname.split('/');
  const crumbs = splitPaths.map((p, idx) => {
    if (idx > 0 && idx <= 2) {
      const Component =
        idx === 1
          ? currentUser.isTMUser
            ? BreadCrumbLink
            : BreadCrumbNoLink
          : BreadCrumbLink;

      const link = splitPaths.slice(0, idx + 1).join('/');

      const entityType = currentEntity && currentEntity.type;

      const def = startCase(p);
      const display =
        entityType === entityTypes.customer
          ? currentEntity.id === p
            ? currentEntity.name
            : def
          : def;

      return (
        <Fragment>
          <Component to={link} key={idx}>
            {display}
          </Component>
          {idx !== splitPaths.length - 1 && <BreadCrumbsSeparator />}
        </Fragment>
      );
    }
    return null;
  });
  return <BreadCrumbsContainer>{crumbs}</BreadCrumbsContainer>;
});

export default BreadCrumbs;
