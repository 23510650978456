import { getIn } from 'formik';
import { lighten } from 'polished';
import React from 'react';
import styled from 'styled-components';
import InfoBubble from '../InfoBubble';

const Label = styled.div`
  font-size: 0.75rem;
  margin-bottom: 5px;
`;
const InputContainer = styled.div`
  position: relative;
  > p {
    position: absolute;
    right: 10px;
    bottom: 10px;
    font-size: 0.75rem;
  }
`;
const InfoContainer = styled.div`
  position: relative;
  > p {
    position: absolute;
    right: 10px;
    bottom: 10px;
    font-size: 0.75rem;
  }
`;
const TextUnitAndInputWrapper = styled.div`
  position: relative;
  > p {
    position: absolute;
    right: 10px;
    bottom: 10px;
    font-size: 0.75rem;
  }
  > :last-child {
    position: absolute;
    right: 10px;
    top: 0;
    font-size: 0.75rem;
  }
`;

let TextFormField = ({
  infoText,
  noError,
  form,
  labelText,
  unit,
  onChange,
  value,
  field = {},
  ...props
}) => {
  const errorText =
    field.name &&
    getIn(form.touched, field.name) &&
    getIn(form.errors, field.name);
  // const errorMessage = errorText
  //   ? errorText.replace(field.name, labelText)
  //   : null;
  const inputComponent = noError ? (
    <input
      type="text"
      id={field.name}
      value={value}
      onChange={onChange}
      {...field}
      {...props}
    />
  ) : unit && infoText ? (
    <TextUnitAndInputWrapper>
      <input
        type="text"
        id={field.name}
        value={value}
        onChange={onChange}
        {...field}
        {...props}
      />
      {!errorText && (
        <p style={{ color: props.disabled ? '#ccc' : '#333' }}>{unit}</p>
      )}
      <InfoBubble text={infoText} />
    </TextUnitAndInputWrapper>
  ) : unit ? (
    <InputContainer>
      <input
        type="text"
        id={field.name}
        value={value}
        onChange={onChange}
        {...field}
        {...props}
      />
      {!errorText && (
        <p style={{ color: props.disabled ? '#ccc' : '#333' }}>{unit}</p>
      )}
    </InputContainer>
  ) : infoText ? (
    <InfoContainer>
      <input
        type="text"
        id={field.name}
        onChange={onChange}
        value={value}
        {...field}
        {...props}
      />
      <InfoBubble text={infoText} />
    </InfoContainer>
  ) : (
    <input
      type="text"
      id={field.name}
      onChange={onChange}
      {...field}
      {...props}
    />
  );
  return (
    <div>
      {labelText && <Label>{labelText}</Label>}
      {inputComponent}
    </div>
  );
};

TextFormField = styled(TextFormField)`
  height: 40px;
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.colors.mediumGrey};
  width: 100%;
  padding: 0.5rem;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: ${({ theme }) => lighten(0.1, theme.colors.mediumGrey)};
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: ${({ theme }) => lighten(0.1, theme.colors.mediumGrey)};
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: ${({ theme }) => lighten(0.1, theme.colors.mediumGrey)};
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: ${({ theme }) => lighten(0.1, theme.colors.mediumGrey)};
  }
`;
export default TextFormField;
