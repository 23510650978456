import React, { createContext, useState, useCallback } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { theme as initialTheme } from '../styles';

const ThemeContext = createContext();

const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(initialTheme);
  const resetTheme = useCallback(() => setTheme(initialTheme), []);
  const modifyTheme = useCallback(
    (primaryColor, secondaryColor) =>
      setTheme((prev) => ({
        ...prev,
        colors: {
          ...prev.colors,
          primary: primaryColor,
          secondary: secondaryColor,
        },
      })),
    [],
  );
  return (
    <StyledThemeProvider theme={theme}>
      <ThemeContext.Provider value={{ modifyTheme, resetTheme }}>
        {children}
      </ThemeContext.Provider>
    </StyledThemeProvider>
  );
};

export { ThemeContext, ThemeProvider };
