import { gql } from '@apollo/client';

const LOGIN = gql`
  query customerPortalLogin(
    $username: String
    $password: String
    $yardToken: String
  ) {
    customerPortalLogin(
      username: $username
      password: $password
      yardToken: $yardToken
    ) {
      token
      error
      session
      passwordReset
    }
  }
`;

export { LOGIN };
