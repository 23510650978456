import { useMutation } from '@apollo/client';
import { Email20, Printer20 } from '@carbon/icons-react';
import { Field, Form, Formik } from 'formik';
import { get } from 'lodash';
import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import * as yup from 'yup';
import {
  ActionButtons,
  PageError,
  PageLoading,
  TextFormField,
} from '../../components';
import { apis, routing } from '../../config';
import { axios } from '../../connections';
import { formatDashDateTime } from '../../functions';
import { FormWrapper, OnClickLink, TableIconWrapper } from '../../styles';
import { EMAIL_EPOD } from './gql';

const DivWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StatusIndicator = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 100px;
  background: ${({ status, theme }) =>
    theme.colors.statuses[status] || theme.colors.primary};
`;

const ComponentContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StatusContainer = styled.div`
  width: 60%;
  height: 70%;
  border: 1px solid ${({ theme }) => theme.colors.mediumGrey};
  display: grid;
  grid-template-rows: 1.5fr repeat(5, 1fr);
`;

const StatusContainerRow = styled.div`
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  > div:last-child {
    font-weight: bold;
  }
  ${({ header, theme, status }) =>
    header &&
    css`
      background: ${theme.colors.lightGrey};
      border-bottom: 10px solid
        ${theme.colors.statuses[status] || theme.colors.primary};
      & div {
        font-weight: bold;
      }
    `}
`;

const headers = [
  {
    key: 'orderNo',
    header: 'Order Number',
  },
  {
    key: 'deliveryNote',
    header: 'Delivery Note',
  },
  {
    key: 'deliveryDate',
    header: 'Delivery Date',
    isDateTime: true,
  },
  {
    key: 'organisationName',
    header: 'Transporter',
  },
  {
    key: 'vehicle',
    header: 'Vehicle',
  },
  {
    key: 'driver',
    header: 'Driver',
  },
  {
    key: 'consigneeName',
    header: 'Entity Name',
  },
  {
    key: 'status',
    header: 'Status',
    componentType: 'modal',
    ComponentWrapper: ({ value, onClick }) => {
      return (
        <DivWrapper onClick={onClick}>
          <StatusIndicator status={value} />
          <OnClickLink>{value}</OnClickLink>
        </DivWrapper>
      );
    },
    Component: ({ row, currentEntity }) => {
      const load = get(row, 'load', {});
      const stopData = `${load.stopNo} / ${load.totalStops}` || '';
      const { token } =
        currentEntity.organisations.find((o) => o.id === row.organisationId) ||
        {};
      const [eta, setEta] = useState(load.plannedEta);
      const [loading, setLoading] = useState(true);
      const [error, setError] = useState();

      useEffect(() => {
        axios
          .get(apis.getPlan(row.organisationId, load.id), {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then(({ data }) => {
            const stops = get(data, 'data.stops', []);
            const thisStop = stops.find((s) => s.id === load.poiId);
            thisStop && setEta(thisStop.dueTime);
          })
          .catch((e) => setError(e))
          .finally(() => setLoading(false));
      }, [load.id, row.organisationId, token, load.poiId]);

      if (loading) {
        return <PageLoading />;
      }

      if (error) {
        return <PageError message={error.message} />;
      }

      return (
        <ComponentContainer>
          <StatusContainer>
            <StatusContainerRow header status={row.status}>
              <div>{row.orderNo}</div>
              <div>Status: {row.status}</div>
            </StatusContainerRow>
            <StatusContainerRow>
              <div>Transporter</div>
              <div>{row.organisationName}</div>
            </StatusContainerRow>
            <StatusContainerRow>
              <div>ETA</div>
              <div>{formatDashDateTime(eta)}</div>
            </StatusContainerRow>
            <StatusContainerRow>
              <div>Vehicle Registration</div>
              <div>{row.vehicle}</div>
            </StatusContainerRow>
            <StatusContainerRow>
              <div>Driver Name</div>
              <div>{row.driver}</div>
            </StatusContainerRow>
            <StatusContainerRow>
              <div>Stop Number</div>
              <div>{stopData}</div>
            </StatusContainerRow>
          </StatusContainer>
        </ComponentContainer>
      );
    },
  },
  {
    key: 'print',
    header: '',
    componentType: 'link',
    ComponentWrapper: ({ onClick, value }) => (
      <TableIconWrapper onClick={onClick}>
        <Printer20 />
      </TableIconWrapper>
    ),
    componentLink: routing.customer.printEpod,
    condition: (row) => row.status === 'Complete',
  },
  {
    key: 'email',
    header: '',
    componentType: 'modal',
    condition: (row) => row.status === 'Complete',
    ComponentWrapper: ({ onClick, value }) => (
      <TableIconWrapper onClick={onClick}>
        <Email20 />
      </TableIconWrapper>
    ),
    Component: ({ row, close, notificationsContext, currentEntity }) => {
      const { addInlineAlert } = notificationsContext;

      const [emailEpod, { loading }] = useMutation(EMAIL_EPOD, {
        onCompleted: () => {
          addInlineAlert('success', 'Email Sent', `EPOD has been sent.`);
          close();
        },
        onError: (err) => {
          addInlineAlert('error', 'Error', err.message);
          close();
        },
      });

      return (
        <Formik
          initialValues={{ email: '' }}
          validationSchema={yup.object({
            email: yup.string().email().required(),
          })}
          onSubmit={({ email }) => {
            const variables = {
              input: {
                email,
                subject: `Electronic Delivery Note - ${row.reference}`,
                fromName: row.consignorName,
                templateSchema: {
                  customerPortalCustomer: row.consignorName,
                  customer: row.consigneeName,
                  consignmentReference: row.reference,
                },
                apiSchema: {
                  customerId: currentEntity.id,
                  loadId: row.load.id,
                  consignmentId: row.id,
                },
              },
            };
            emailEpod({ variables });
          }}
        >
          <FormWrapper style={{ height: '150px' }}>
            <Form>
              <Field
                label="Email Address"
                labelText="Email Address"
                name="email"
                component={TextFormField}
                light
              />
            </Form>
            <ActionButtons
              type={'finalStep'}
              primaryName="Send EPOD"
              loading={loading}
            />
          </FormWrapper>
        </Formik>
      );
    },
  },
];

export default headers;
