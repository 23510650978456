import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import { PageLoading } from '../components';
import { routing, sideNav } from '../config';
import { LoginContext, SideNavContext } from '../context';
import {
  LandingPage,
  PrintEPOD,
  Summary,
  TransporterDashboard,
} from '../modules';
import TMUser from './TMUser';
import User from './User';

const Routes = () => {
  const { currentEntity, currentUser } = useContext(LoginContext);
  const { setSideNavOptions } = useContext(SideNavContext);
  setSideNavOptions(currentEntity.id ? sideNav.customer : sideNav.tm);
  if (!currentEntity.id && !currentUser.isTMUser) {
    return <PageLoading />;
  }
  const userComponent = currentUser.isTMUser ? (
    <TMUser />
  ) : (
    <User currentUser={currentUser} />
  );
  return (
    <Switch>
      <Route path={routing.customer.printEpod} component={PrintEPOD} />
      <Route
        path={routing.customer.transporterDashboard}
        component={TransporterDashboard}
      />
      <Route path={routing.customer.summary} component={Summary} />
      <Route path={routing.customer.root} component={LandingPage} />
      {userComponent}
    </Switch>
  );
};

export default Routes;
