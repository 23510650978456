import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { localStorageKeys } from '../../config';
import PageError from '../PageError';
import PageLoading from '../PageLoading';
import { PDFContainer, PrintViewContainer } from './styles';

const PrintView = ({ apiPath = '', body = {} }) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [blob, setBlob] = useState();
  const fetchPdf = useCallback(
    (data) => {
      setBlob();
      setError();
      setLoading(true);
      axios({
        url: `${process.env.REACT_APP_REPORTING_SERVER}/${apiPath}`,
        // url: `http://0.0.0.0:8080/${apiPath}`,
        method: 'POST',
        responseType: 'blob',
        data,
        headers: {
          authorization: localStorage.getItem(localStorageKeys.idToken),
        },
      })
        .then((d) => {
          setBlob(d.data);
        })
        .catch((e) => {
          setError(e);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [apiPath],
  );

  useEffect(() => {
    fetchPdf(body);
  }, [body, fetchPdf]);

  return (
    <PrintViewContainer>
      {loading ? (
        <PageLoading />
      ) : error ? (
        <PageError message={error.message} />
      ) : (
        blob && (
          <PDFContainer>
            <iframe
              title="Print View"
              name="PRINTABLE"
              src={URL.createObjectURL(blob)}
            ></iframe>
          </PDFContainer>
        )
      )}
    </PrintViewContainer>
  );
};

export default PrintView;
