import jwt from 'jsonwebtoken';
import { clearAllLocalStorageKeys } from '../../../functions';
import { localStorageKeys } from '../../../config';
import { addHours, isPast, fromUnixTime } from 'date-fns';

const loggedIn = () => {
  const idToken = localStorage.getItem(localStorageKeys.idToken);
  if (!idToken || idToken === undefined) {
    clearAllLocalStorageKeys();
    return false;
  }
  const decoded = jwt.decode(idToken);
  if (!decoded || (decoded && !decoded.payload)) {
    clearAllLocalStorageKeys();
    return false;
  }
  if (isPast(addHours(fromUnixTime(decoded.payload.exp), 23))) {
    clearAllLocalStorageKeys();
    return false;
  }
  if (localStorage.getItem(localStorageKeys.rememberMe) === 'true') {
    return true;
  }
  return true;
};

export { loggedIn };
