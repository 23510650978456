import { useLazyQuery } from '@apollo/client';
import { Field, Formik } from 'formik';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import {
  ActionButtons,
  CheckBoxFormField,
  InlineNotification,
  PasswordFormField,
  TextFormField,
} from '../../../../components';
import { localStorageKeys } from '../../../../config';
import { LoadingContext } from '../../../../context';
import { Form } from '../../../../styles';
import Banner from '../Banner';
import formikSchema from './formikSchema';
import { LOGIN } from './gql';
import { ForgotPassword, FormArea, FormFooter, FormItem } from './styles';

const LoginForm = ({
  updateSession,
  updateUsername,
  updateResetPassword,
  closeModalAndRedirect,
  setNotify,
  yardToken,
}) => {
  const { setLoading } = useContext(LoadingContext);
  const [rememberme, setRememberme] = useState('false');

  const [login, { loading }] = useLazyQuery(LOGIN, {
    onError: (err) =>
      setNotify(
        <InlineNotification
          kind="error"
          title="Error"
          subtitle={err.message}
        />,
      ),
    onCompleted: (data) => {
      if (!data) {
        setNotify(
          <InlineNotification
            kind="error"
            title="Error"
            subtitle="please make sure that you have entered a valid username and password"
          />,
        );
      } else if (data.customerPortalLogin.passwordReset) {
        updateSession(data.customerPortalLogin.session);
      } else {
        localStorage.setItem(localStorageKeys.rememberMe, rememberme);
        closeModalAndRedirect(data.customerPortalLogin.token);
      }
    },
  });

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  useEffect(() => {
    if (yardToken) {
      login({ variables: { yardToken } });
    }
  }, [login, yardToken]);

  return (
    <Formik
      {...formikSchema}
      onSubmit={(values) => {
        setRememberme(`${values.rememberme || false}`);
        const { username, password } = values;
        updateUsername(username);
        login({ variables: { username, password: password.trim() } });
      }}
    >
      <Fragment>
        <Banner text="Welcome Back" subtext="Login to Continue" />
        <Form>
          <FormArea>
            <FormItem>
              <Field
                placeholder="username"
                labelText="Username"
                name="username"
                component={TextFormField}
                light
              />
            </FormItem>
            <FormItem>
              <Field
                labelText="Password"
                showPasswordLabel="Show"
                hidePasswordLabel="Hide"
                name="password"
                component={PasswordFormField}
                light
              />
            </FormItem>
            <FormFooter>
              <Field
                labelText="Remember Me"
                name="rememberme"
                type="checkbox"
                component={CheckBoxFormField}
              />
            </FormFooter>
          </FormArea>
          <ActionButtons primaryName="Login" fillWidth noMargin>
            Login
          </ActionButtons>
          <ForgotPassword
            onClick={() => {
              updateResetPassword(true);
            }}
          >
            Forgot Password?
          </ForgotPassword>
        </Form>
      </Fragment>
    </Formik>
  );
};

export default LoginForm;
