import React, { useContext } from 'react';
import { TableViewForGql } from '../../../components';
import { linkPlaceholders, routing } from '../../../config';
import { LoginContext } from '../../../context';
import { shapeData } from './functions';
import { GET_CUSTOMERS } from './gql';
import headings from './headings';

const filterOptions = [
  { name: 'name', type: 'input', placeholder: 'Customer' },
];

const CustomerList = () => {
  const { linkParams } = useContext(LoginContext);
  return (
    <TableViewForGql
      gql={GET_CUSTOMERS}
      queryName="getOrgsPaged"
      paginationObject="getOrgsPaged"
      filterOptions={filterOptions}
      initialOrder={[['name', 'ASC']]}
      initialLinkParamObject={linkParams}
      additionalLinkParam={linkPlaceholders.customerId}
      headings={headings}
      dataShapeFunction={shapeData}
      heading="Customers"
      description="View Customers"
      noActions
      enter={routing.customer.root}
      fixedQueries={{ hasCustomerPortal: true }}
    />
  );
};

export default CustomerList;
