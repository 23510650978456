import styled from 'styled-components';
import { PrimaryButton } from '../Buttons/index';
import React from 'react';
import { useFormikContext } from 'formik';
import { Link } from '../../styles';
import Loading from '../Spinner';

const ActionButtons = ({
  noMargin,
  fillWidth,
  primaryName,
  secondaryName,
  onClickPrimary,
  onClickSecondary,
  type,
  to,
  onClick,
  disabled,
  className,
  loading,
}) => {
  const { submitForm, isValid, dirty } = useFormikContext();
  switch (type) {
    case 'finalStep':
      return (
        <PrimaryButton
          fillWidth={fillWidth}
          className={className}
          disabled={disabled ? disabled : !isValid || !dirty}
          onClick={onClickPrimary || (() => submitForm())}
        >
          {loading ? (
            <Loading small withOverlay={false} />
          ) : (
            primaryName || 'Save Changes'
          )}
        </PrimaryButton>
      );
    case 'finalFormButton':
      return (
        <PrimaryButton
          type="submit"
          fillWidth={fillWidth}
          className={className}
          oneButton
          disabled={disabled !== undefined ? disabled : !isValid || !dirty}
        >
          {loading ? (
            <Loading small withOverlay={false} />
          ) : (
            primaryName || 'Save Changes'
          )}
        </PrimaryButton>
      );
    case 'steps':
      return (
        <PrimaryButton
          fillWidth={fillWidth}
          className={className}
          oneButton
          disabled={disabled}
          onClick={onClickPrimary}
          type="button"
        >
          {loading ? (
            <Loading small withOverlay={false} />
          ) : disabled ? (
            'Next'
          ) : (
            <Link to={to}>{primaryName || 'Next'}</Link>
          )}
        </PrimaryButton>
      );
    default:
      return (
        <PrimaryButton
          fillWidth={fillWidth}
          className={className}
          oneButton
          disabled={!isValid || !dirty}
          onClick={onClickPrimary || (() => submitForm())}
        >
          {loading ? (
            <Loading small withOverlay={false} />
          ) : (
            primaryName || 'Save Changes'
          )}
        </PrimaryButton>
      );
  }
};

export default styled(ActionButtons)`
  display: flex;
  margin-top: ${({ noMargin }) => (noMargin ? '0' : '2rem')};
`;
