import React, { createContext, useState } from 'react';

const NotificationsContext = createContext();

const NotificationsProvider = ({ children }) => {
  const [inlineAlert, setInlineAlert] = useState();

  const closeAlert = () => {
    setInlineAlert(null);
  };

  const addInlineAlert = (type, title, message) => {
    setInlineAlert({
      title,
      message,
      type,
    });
    setTimeout(() => {
      closeAlert();
    }, 5000);
  };

  return (
    <NotificationsContext.Provider value={{ inlineAlert, addInlineAlert }}>
      {children}
    </NotificationsContext.Provider>
  );
};

export { NotificationsProvider, NotificationsContext };
