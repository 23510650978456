import React, { Fragment, useContext, useEffect, useState } from 'react';
import { PageError, PageLoading } from '../../components';
import { apis } from '../../config';
import { axios } from '../../connections';
import { LoginContext, RightSideViewContext } from '../../context';
import DeliveryStatusSideBar from '../DeliveryStatusSideBar';
import {
  OrgHeader,
  StackedBar,
  SummaryCard,
  SummaryContainer,
  Status,
} from './styles';
import { uniq } from 'ramda';
import { orderBy } from 'lodash-es';

const Summary = () => {
  const { currentEntity } = useContext(LoginContext);
  const { setIsRightSideViewOpen } = useContext(RightSideViewContext);
  const [payload, setPayload] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  useEffect(() => {
    return axios
      .get(apis.portal(currentEntity.id))
      .then(({ data }) => {
        setPayload(data.data.map((d) => d.data));
        setIsRightSideViewOpen(true);
        return () => {
          setIsRightSideViewOpen(false);
        };
      })
      .catch((e) => setError(e))
      .finally(() => {
        setLoading(false);
      });
  }, [setIsRightSideViewOpen, currentEntity.id]);

  if (loading) {
    return <PageLoading />;
  }

  if (error) {
    return <PageError message={error.message} />;
  }

  const orgData = orderBy(
    uniq(payload.map((p) => p.organisationName)).map((org) => ({
      org,
      data: payload.filter((p) => p.organisationName === org),
    })),
    ['org'],
    ['asc'],
  );

  const statuses = uniq(payload.map((p) => p.status)).sort();

  return (
    <Fragment>
      <SummaryContainer>
        <h2>Summary Dashboard</h2>
        {orgData.map(({ org, data }) => {
          const drops = data.filter((d) => statuses.includes(d.status)).length;

          const statusData = statuses
            .map((status) => {
              const statusDrops = data.filter((d) => d.status === status);
              return {
                status,
                dropCount: statusDrops.length,
                dropPercent:
                  drops === 0
                    ? 0
                    : `${((statusDrops.length / drops) * 100).toFixed()}%`,
              };
            })
            .filter(({ dropCount }) => dropCount);

          return (
            <SummaryCard key={org}>
              <OrgHeader>
                <h3>{org}</h3>
                <h4>{drops} Drops</h4>
              </OrgHeader>
              <StackedBar>
                {statusData.map((sd) => (
                  <Status
                    key={sd.status}
                    status={sd.status}
                    width={sd.dropPercent}
                  >
                    <div>{sd.status}</div>
                    <div>{`${sd.dropPercent} (${sd.dropCount})`}</div>
                  </Status>
                ))}
              </StackedBar>
            </SummaryCard>
          );
        })}
      </SummaryContainer>
      <DeliveryStatusSideBar payload={payload} />
    </Fragment>
  );
};

export default Summary;
