import React from 'react';
import styled from 'styled-components';
import { Checkmark24 } from '@carbon/icons-react';

const Label = styled.div`
  font-size: 0.75rem;
  margin-left: 0.9rem;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledCheckbox = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.5rem;
  color: ${({ theme }) => theme.colors.white};
  font-weight: bold;
  width: 2rem;
  height: 2rem;
  background: ${({ checked, theme }) =>
    checked ? theme.colors.primary : theme.colors.white};
  border: 1px solid
    ${({ theme, checked }) => theme.colors[checked ? 'primary' : 'mediumGrey']};
  border-radius: 3px;
  transition: all 150ms;
`;

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

export const CheckBoxFormField = ({ field, labelText, className }) => {
  const { value, onChange } = field;
  return (
    <Container>
      <CheckboxContainer className={className}>
        <StyledCheckbox
          checked={value}
          onClick={() => {
            onChange({ target: { value: !value, name: field.name } });
          }}
        >
          {value && <Checkmark24 />}
        </StyledCheckbox>
      </CheckboxContainer>
      <Label>{labelText}</Label>
    </Container>
  );
};

export default CheckBoxFormField;
