import React from 'react';
import { Container, StatusBox, StatusBoxContainer } from './styles';

const DeliveryStatusSideBar = ({ payload = [] }) => {
  const statuses = [
    'Drops',
    'Unallocated',
    'Allocated',
    'Scheduled',
    'Active',
    'Failed',
    'Complete',
    'Unactioned',
  ];
  return (
    <Container>
      <h2>Delivery Status</h2>
      <StatusBoxContainer>
        {statuses.map((s) => {
          const value =
            s === 'Drops'
              ? payload.length
              : payload.filter((p) => p.status === s).length;
          return (
            <StatusBox status={s}>
              <h3>{s}</h3>
              <h2>{value}</h2>
            </StatusBox>
          );
        })}
      </StatusBoxContainer>
    </Container>
  );
};

export default DeliveryStatusSideBar;
