import { lighten, readableColor } from 'polished';
import styled from 'styled-components';

const SummaryContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const SummaryCard = styled.div`
  background: ${({ theme }) => theme.colors.lightGrey};
  width: 100%;
  height: 7rem;
  margin-top: 1.5rem;
  padding: 0.5rem;
  display: grid;
  grid-template-columns: 2fr 7fr;
  align-items: center;
  justify-items: center;
`;

const OrgHeader = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  & * {
    text-align: center;
  }
`;

const StackedBar = styled.div`
  display: flex;
  width: 100%;
  height: 50%;
`;

const Status = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.lightGrey};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${({ theme, status }) =>
    theme.colors.statuses[status] || theme.colors.primary};
  width: ${({ width }) => width};
  font-size: 0.65rem;
  font-weight: bold;
  color: ${({ theme, status }) =>
    readableColor(theme.colors.statuses[status] || theme.colors.primary)};
  text-align: center;
  > div {
    display: none;
  }
  :hover {
    > div {
      display: block;
    }
    background: ${({ theme, status }) =>
      lighten(0.1, theme.colors.statuses[status] || theme.colors.primary)};
  }
`;

export { SummaryContainer, SummaryCard, OrgHeader, StackedBar, Status };
