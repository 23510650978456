import { useFormikContext } from 'formik';
import React from 'react';
import Select from 'react-select';
import { shapeItemsArray, shapeSelectedItems } from './functions';
import styled from 'styled-components';

const MultiSelect = ({
  display,
  id,
  field: { value, name },
  form,
  label,
  labelText,
  returnFullObject,
  noMoreItemsText,
  items,
  ...props
}) => {
  const { handleChange } = useFormikContext();
  const shapedItems = shapeItemsArray(items, display, id);
  const shapedSelectedItems = shapeSelectedItems(value, shapedItems);
  return (
    <Select
      isMulti={true}
      closeMenuOnSelect={false}
      noOptionsMessage={() => noMoreItemsText}
      onChange={(v) => {
        handleChange({
          target: {
            value: returnFullObject
              ? v?.map((item) => ({
                  [display || 'label']: item.label,
                  [id || 'value']: item.value,
                }))
              : v?.map((item) => item.value),
            name,
          },
        });
      }}
      value={shapedSelectedItems}
      options={shapedItems}
      {...props}
    />
  );
};

export default styled(MultiSelect)`
  .css-12jo7m5 {
    font-size: 0.875rem;
  }
`;

//   const errorText = getIn(form.touched, name) && getIn(form.errors, name);
//   const errorMessage = errorText ? errorText : null;
//       invalid={!!errorText}
//       invalidText={errorMessage}
