import { format } from 'date-fns';
import { useFormikContext } from 'formik';
import { lighten } from 'polished';
import React from 'react';
import DateSelector from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';

const NoTypeText = styled.p`
  font-size: 0.75rem;
  color: ${({ theme, hasValue }) =>
    hasValue ? theme.colors.darkGrey : lighten(0.1, theme.colors.mediumGrey)};
  cursor: pointer;
`;

const Picker = styled.div`
  padding: 10px;
`;

const DatePicker = ({
  className,
  labelText,
  field: { name, value },
  form,
  minDate,
  disabled,
  onChange,
  dateFormat,
  dateValueFormat,
  placeholderText,
  noTypeIn,
  ...props
}) => {
  const { handleChange } = useFormikContext();
  const NoTypeIn = ({ value, onClick }) => (
    <NoTypeText hasValue={!!value} onClick={onClick}>
      {value || 'Click to Select a Value'}
    </NoTypeText>
  );
  const noTypeInProp = noTypeIn ? { customInput: <NoTypeIn /> } : {};
  const ClickHere = ({ onClick }) => {
    const display =
      value && value.from && value.to
        ? `${value.from} - ${value.to}`
        : 'Date Range';
    return <Picker onClick={onClick}>{display}</Picker>;
  };
  return (
    <div className={className}>
      <p
        style={{
          margin: '5px',
          fontSize: '0.75rem',
          marginLeft: '0',
          color: '#565656',
        }}
      >
        {labelText}
      </p>
      <DateSelector
        selectsRange
        customInput={<ClickHere />}
        shouldCloseOnSelect={false}
        startDate={value && value.from && new Date(value.from)}
        endDate={value && value.to && new Date(value.to)}
        disabled={disabled}
        minDate={minDate}
        id={name}
        selected={value && value.from && new Date(value.from)}
        maxDate={new Date('2100-01-01')}
        allowSameDay
        title={labelText}
        required
        dateFormat={dateFormat || 'yyyy/MM/dd'}
        onChange={(dates) => {
          handleChange({
            target: {
              value: {
                from: dates[0] && format(dates[0], 'yyyy-MM-dd'),
                to: dates[1] && format(dates[1], 'yyyy-MM-dd'),
              },
              name,
            },
          });
        }}
        {...props}
        {...noTypeInProp}
      />
    </div>
  );
};

export default styled(DatePicker)`
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.colors.inputBottom};
  & .react-datepicker-wrapper,
  & .react-datepicker__input-container {
    border: none;
    width: 100%;
  }
  & input {
    width: 100%;
    border: none;
    background: none;
    padding: 10px;
    cursor: pointer;
    :disabled {
      cursor: not-allowed;
      background: ${({ theme }) => lighten(0.1, theme.colors.lightGrey)};
    }
  }
`;
