import React, { useContext } from 'react';
import Dropdown from 'react-select';
import { useFormikContext } from 'formik';
import { ThemeContext } from 'styled-components';
import { Container, Label } from './styles';

const DropdownBox = ({
  field,
  labelText,
  options,
  onChange,
  value,
  noBorder = false,
  placeholder = 'Select...',
  disabled = false,
  isClearable = true,
}) => {
  const theme = useContext(ThemeContext);
  const styles = {
    control: (provided) => {
      return {
        ...provided,
        boxShadow: 'none',
        border: 'none',
        borderRadius: 0,
        borderBottom: noBorder
          ? 'none'
          : `1px solid ${theme.colors.mediumGrey}`,
        '&:hover': {
          borderBottom: noBorder
            ? 'none'
            : `1px solid ${theme.colors.mediumGrey}`,
        },
      };
    },
    indicatorSeparator: (provided) => {
      return {
        ...provided,
        display: 'none',
      };
    },
    placeholder: (provided) => {
      return {
        ...provided,
        fontSize: '0.75rem',
      };
    },
    singleValue: (provided) => {
      return {
        ...provided,
        fontSize: '0.75rem',
      };
    },
    option: (provided, state) => {
      return {
        ...provided,
        cursor: 'pointer',
        fontSize: '0.75rem',
        background: state.isSelected ? theme.colors.primary : 'none',
        '&:hover': {
          background: state.isSelected
            ? theme.colors.primary
            : theme.colors.lightGrey,
        },
      };
    },
    menu: (provided) => {
      return { ...provided, zIndex: 10000000 };
    },
  };
  const formikContext = useFormikContext();
  if (field) {
    //ie is this component in a formik?
    const { name, value: formikValue } = field;
    return (
      <Container>
        <Label>{labelText}</Label>
        <Dropdown
          isClearable={isClearable}
          isDisabled={disabled}
          placeholder={placeholder}
          value={options.find((i) => i.value === formikValue)}
          options={options}
          onChange={(v) => {
            onChange && onChange(v);
            formikContext.handleChange({
              target: {
                value: v && v.value,
                name,
              },
            });
          }}
          blurInputOnSelect
          styles={styles}
        />
      </Container>
    );
  }
  return (
    <Container>
      <Label>{labelText}</Label>
      <Dropdown
        isClearable={isClearable}
        isDisabled={disabled}
        placeholder={placeholder}
        value={options.find((i) => i.value === value)}
        options={options}
        onChange={(v) => {
          onChange(v);
        }}
        blurInputOnSelect
        styles={styles}
      />
    </Container>
  );
};
export default DropdownBox;
