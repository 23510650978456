import { Field, Form, Formik } from 'formik';
import { mergeAll, reject } from 'ramda';
import React from 'react';
import {
  CheckBoxFormField,
  DateRangePicker,
  Dropdown,
  MultiSelect,
  TextFormField,
} from '../../../components';
import { ActionButton } from '../ActionRow/styles';
import { FilterItem, FilterOptionsContainer } from './styles';

const FilterOptions = ({ filterOptions, setQuery, setSkip }) => {
  const initialValues = mergeAll(
    filterOptions.map(({ name }) => ({ [name]: null })),
  );
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        setSkip(0);
        setQuery(reject((v) => !v, values));
      }}
    >
      {({ resetForm }) => {
        return (
          <Form>
            <FilterOptionsContainer>
              {filterOptions.map((filter) => {
                const Component =
                  filter.type === 'dropdown'
                    ? Dropdown
                    : filter.type === 'multiselect'
                    ? MultiSelect
                    : filter.type === 'daterange'
                    ? DateRangePicker
                    : filter.type === 'checkbox'
                    ? CheckBoxFormField
                    : TextFormField;
                return (
                  <FilterItem key={filter.name} width={200}>
                    <Field {...filter} component={Component} light />
                  </FilterItem>
                );
              })}
              <ActionButton>Search</ActionButton>
              <ActionButton
                type="button"
                onClick={() => {
                  setSkip(0);
                  setQuery({});
                  resetForm({ values: initialValues });
                }}
              >
                Clear
              </ActionButton>
            </FilterOptionsContainer>
          </Form>
        );
      }}
    </Formik>
  );
};

export default FilterOptions;
