import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { trackmaticLogo } from '../../assets';
import { routing } from '../../config';
import { LoginContext, ModalContext } from '../../context';
import { generateLinkWithParams } from '../../functions';
import { DividerLine } from '../../styles';
import BreadCrumbs from './BreadCrumbs';
import LogoutModal from './LogoutModal';
import { HeaderContainer, Logo, Logout } from './styles';

const Header = () => {
  const { currentEntity, currentUser, linkParams } = useContext(LoginContext);
  const { setModalContent, toggleModal } = useContext(ModalContext);
  return (
    <HeaderContainer>
      <Link
        to={
          currentUser.isTMUser
            ? '/'
            : generateLinkWithParams(routing.customer.root, linkParams)
        }
        style={{ height: '100%' }}
      >
        <Logo
          src={
            (currentEntity &&
              (currentEntity.parent
                ? currentEntity.parent.logo
                : currentEntity.logo)) ||
            trackmaticLogo
          }
        />
      </Link>
      <DividerLine />
      <BreadCrumbs />
      <Logout
        style={{ justifySelf: 'flex-end' }}
        onClick={() => {
          toggleModal();
          setModalContent(<LogoutModal close={toggleModal} />);
        }}
      >
        Logout
      </Logout>
    </HeaderContainer>
  );
};

export default Header;
