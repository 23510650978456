import { gql } from '@apollo/client';

const GET_USER = gql`
  query getCurrentUser {
    getCurrentUser {
      id
      firstName
      lastName
      email
      isTMUser
      isCustomerPortalUser
      orgs {
        id
        name
        logo
        hasCustomerPortal
        primaryColor
        secondaryColor
      }
    }
  }
`;

export { GET_USER };
