import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { linkPlaceholders, routing } from '../config';
import { generateLinkWithParams } from '../functions';

const User = ({ currentUser }) => {
  return (
    <Switch>
      <Redirect
        to={generateLinkWithParams(routing.customer.root, {
          [linkPlaceholders.customerId]: currentUser.orgs[0].id,
        })}
      />
    </Switch>
  );
};

export default User;
