const headings = [
  {
    key: 'name',
    header: 'Customer',
    allowOrder: true,
  },
  {
    key: 'hasLogo',
    header: 'Logo',
    isCount: true,
  },
  {
    key: 'primaryColor',
    header: 'Primary Colour',
    componentType: 'color',
    isCount: true,
  },
  {
    key: 'secondaryColor',
    header: 'Secondary Colour',
    componentType: 'color',
    isCount: true,
  },
];

export default headings;
