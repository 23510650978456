import styled from 'styled-components';

const TopBar = styled.div`
  display: grid;
  grid-template-columns: auto ${({ hasFilters }) => (hasFilters ? '1fr ' : '')} ${({
      titleOnOwnLine,
    }) => (!titleOnOwnLine ? '1fr' : '')};
  align-items: center;
  grid-column-gap: 3%;
  margin-bottom: 15px;
`;

export { TopBar };
