import { convertEnumString } from '../../functions';

const shapeItemsArray = (items, display, id) => {
  if (typeof items[0] !== 'string') {
    if (display) {
      return items.map((item) => ({ value: item[id], label: item[display] }));
    }
    return items;
  }
  return items.map((item) => ({ value: item, label: convertEnumString(item) }));
};
const shapeSelectedItems = (value, shapedItems, display, id) => {
  if (value) {
    if (typeof value[0] !== 'string') {
      return value.map((item) => ({
        value: item[id || 'value'],
        label: item[display || 'label'],
      }));
    }
    return shapedItems.filter((item) => value.includes(item.value));
  }
  return '';
};
export { shapeItemsArray, shapeSelectedItems };
