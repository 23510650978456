import { useQuery } from '@apollo/client';
import { orderBy } from 'lodash-es';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { PageError, PageLoading } from '../../../components';
import { apis, entityTypes, linkPlaceholders } from '../../../config';
import { axios } from '../../../connections';
import { ThemeContext } from '../../Theme';
import { GET_USER } from './gql';

const LoginContext = createContext();

const LoginProvider = withRouter(({ children, location }) => {
  const { modifyTheme, resetTheme } = useContext(ThemeContext);
  const [currentUser, setCurrentUser] = useState();
  const [currentEntity, setCurrentEntity] = useState({});
  const [displayApp, setDisplayApp] = useState(false);
  const [userCustomers, setUserCustomers] = useState([]);
  const [error, setError] = useState();

  useQuery(GET_USER, {
    onCompleted: ({ getCurrentUser: user }) => {
      const orgs = user.orgs
        .filter((o) => o.hasCustomerPortal)
        .map((cg) => ({
          ...cg,
          id: cg.id,
          name: cg.name,
          type: entityTypes.customer,
          parent: null,
        }));
      setUserCustomers(orgs);
      setCurrentUser(user);
    },
    onError: (e) => setError(e),
  });

  useEffect(() => {
    if (currentUser) {
      const customerId = currentUser.isTMUser
        ? location.pathname.split('/')[2]
        : currentUser.orgs[0].id;
      const currentCustomer = userCustomers.find((uc) => uc.id === customerId);
      if (currentCustomer) {
        const bootstrap = async () => {
          try {
            const { data } = await axios.get(
              apis.bootstrap(currentCustomer.id),
            );
            const organisations = orderBy(
              data.data.organisations.map((o) => ({
                id: o.id,
                name: o.name,
                token: (
                  data.tokens.find((t) => t.organisationId === o.id) || {}
                ).token,
              })),
              ['name'],
              ['asc'],
            );
            setCurrentEntity({ ...currentCustomer, organisations });
            const { primaryColor, secondaryColor } = currentCustomer;
            if (primaryColor && secondaryColor) {
              modifyTheme(primaryColor, secondaryColor);
            }
            setDisplayApp(true);
          } catch (e) {
            setError(e);
          }
        };
        bootstrap();
      } else {
        setCurrentEntity({});
        currentUser.isTMUser && setDisplayApp(true);
        resetTheme();
      }
    }
  }, [
    location.pathname,
    currentUser,
    userCustomers,
    modifyTheme,
    resetTheme,
    displayApp,
  ]);

  if (error) {
    return <PageError message={error.message} />;
  }

  if (displayApp) {
    const linkParams = {
      [linkPlaceholders.customerId]: currentEntity.id,
    };
    return (
      <LoginContext.Provider
        value={{
          currentUser,
          userCustomers,
          currentEntity,
          linkParams,
        }}
      >
        {children}
      </LoginContext.Provider>
    );
  }

  return <PageLoading />;
});

export { LoginContext, LoginProvider };
