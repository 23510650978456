import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Label = styled.div`
  display: flex;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.darkGrey};
  margin: 1px 1px 1px 0;
`;

export { Container, Label };
