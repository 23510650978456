import React, { useState } from 'react';
import { localStorageKeys } from '../../../config';
import { setCustomerPortalAuthToken } from '../../../connections';
import ForgotPasswordForm from './ForgotPasswordForm';
import LoginForm from './LoginForm';
import { loggedIn } from './functions';
import SetPasswordForm from './SetPasswordForm';
import { Container, LoginContainer } from './styles';
import { withRouter } from 'react-router-dom';

const LoginProcess = withRouter(({ children, location }) => {
  const yardToken =
    location.search && new URLSearchParams(location.search).get('yardToken');
  const [resetPassword, updateResetPassword] = useState(false);
  const [session, updateSession] = useState(null);
  const [refuseEntry, considerRefuseEntry] = useState(!loggedIn());
  const [username, updateUsername] = useState('');
  const [notify, setNotify] = useState();
  const redirect = (idToken) => {
    localStorage.setItem(localStorageKeys.idToken, idToken);
    setCustomerPortalAuthToken(idToken);
    considerRefuseEntry(false);
  };
  if (notify) {
    setTimeout(() => {
      setNotify(null);
    }, 3000);
  }
  setInterval(() => {
    considerRefuseEntry(!loggedIn());
  }, 10000);
  if (refuseEntry) {
    return (
      <Container>
        {notify}
        <LoginContainer>
          {resetPassword ? (
            <ForgotPasswordForm
              username={username}
              updateResetPassword={updateResetPassword}
              setNotify={setNotify}
            />
          ) : !session ? (
            <LoginForm
              updateSession={updateSession}
              updateUsername={updateUsername}
              closeModalAndRedirect={redirect}
              updateResetPassword={updateResetPassword}
              setNotify={setNotify}
              yardToken={yardToken}
            />
          ) : (
            <SetPasswordForm
              session={session}
              username={username}
              closeModalAndRedirect={redirect}
              setNotify={setNotify}
            />
          )}
        </LoginContainer>
      </Container>
    );
  }
  return <div>{children}</div>;
});

export default LoginProcess;
