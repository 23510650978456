import React from 'react';
import styled from 'styled-components';
import { theme } from '../../styles';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
  & h1 {
    color: ${() => theme.colors.danger};
    font-weight: bold;
    font-size: 3rem;
  }
`;

const PageError = ({ message }) => (
  <Wrapper>
    <h1>Error</h1>
    <br />
    <p>{message}</p>
  </Wrapper>
);

export default PageError;
