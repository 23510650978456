import styled from 'styled-components';

const TableIconWrapper = styled.div`
  & path,
  & circle {
    fill: ${({ theme }) => theme.colors.primary};
  }
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default TableIconWrapper;
