import styled from 'styled-components';

const BodyContainer = styled.div`
  width: 100%;
  height: calc(100vh - ${({ theme }) => theme.headerHeight});
  color: ${({ theme }) => theme.colors.darkGrey};
  display: grid;
  grid-template-columns: auto 1fr;
`;

const ContentContainer = styled.div`
  width: 100%;
  height: calc(100vh - ${({ theme }) => theme.headerHeight});
  padding: ${({ padding }) => padding && '1rem'};
  transition: 300ms ease;
`;

const RightSideDisplaySplitView = styled.div`
  width: 100%;
  height: calc(100vh - ${({ theme }) => theme.headerHeight});
  display: grid;
  grid-template-columns: ${({ columnWidths }) => columnWidths || '80% 20%'};
  > * {
    padding: 1rem;
  }
`;

export { BodyContainer, ContentContainer, RightSideDisplaySplitView };
