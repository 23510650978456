const theme = {
  colors: {
    statuses: {
      Drops: '#00AFF0',
      Allocated: '#00AFF0',
      Unallocated: '#525252',
      Unactioned: '#FF8218',
      Scheduled: '#feda50',
      Active: '#8f2b91',
      Complete: '#a7e7ad',
      Failed: '#E62700',
    },
    secondary: '#1C1D4C',
    primary: '#00AFF0',
    buttonSecondary: '#393939',
    success: '#63BF42',
    danger: '#E62700',
    warning: '#FF8218',
    broken: '#D8D8D8',
    inputBottom: '#8D8D8D',
    white: '#FFFFFF',
    disabled: '#D8D8D8',
    black: '#000000',
    darkGrey: '#525252',
    mediumGrey: '#8D8D8D',
    lightGrey: '#EEEEEE',
  },
  formColumnWidth: '24rem',
  headerHeight: '48px',
};

export default theme;
