import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Body, Header } from './containers';
import { ApolloProvider } from './connections';
import {
  LoadingProvider,
  LoginProvider,
  ModalProvider,
  NotificationsProvider,
  SideNavProvider,
  ThemeProvider,
  RightSideViewProvider,
} from './context';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Routes from './Routes';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <ApolloProvider>
        <ThemeProvider>
          <NotificationsProvider>
            <ModalProvider>
              <SideNavProvider>
                <LoadingProvider>
                  <LoginProvider>
                    <Header />
                    <RightSideViewProvider>
                      <Body>
                        <Routes />
                      </Body>
                    </RightSideViewProvider>
                  </LoginProvider>
                </LoadingProvider>
              </SideNavProvider>
            </ModalProvider>
          </NotificationsProvider>
        </ThemeProvider>
      </ApolloProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console..log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
