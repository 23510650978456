import { NetworkStatus, useLazyQuery, useQuery } from '@apollo/client';
import { noop } from 'lodash';
import React, { Fragment, useState } from 'react';
import { PaginationContainer } from '../../styles';
import PageError from '../PageError';
import PageHeading from '../PageHeading';
import PageLoading from '../PageLoading';
import Pagination from '../Pagination';
import ActionRow from './ActionRow';
import FilterOptions from './FilterOptions';
import { TopBar } from './styles';
import Table from './Table';

const TableViewForGql = ({
  actionLink,
  actionLinkName,
  additionalLinkParam,
  additionalPageSizes,
  dataShapeFunction,
  defaultTake,
  deleteRow,
  description,
  download,
  enter,
  filterOptions,
  gql,
  gqlVariables,
  heading,
  headings,
  initialLinkParamObject,
  initialOrder,
  navLinks,
  noActions,
  titleOnOwnLine,
  paginationObject,
  queryName,
  keepLoading,
  onCompleted = noop,
  fixedQueries = {},
}) => {
  const [data, setData] = useState();
  const [take, setTake] = useState(defaultTake || 50);
  const [skip, setSkip] = useState(0);
  const [query, setQuery] = useState({});
  const [order, setOrder] = useState(initialOrder || []);

  const { loading, error, refetch, networkStatus } = useQuery(gql, {
    notifyOnNetworkStatusChange: true,
    variables: {
      ...(gqlVariables || {}),
      take,
      skip,
      query: { ...fixedQueries, ...query },
      order,
    },
    onCompleted: (d) => {
      setData(d);
      onCompleted(d);
    },
  });
  const [
    downloadDataQuery,
    { loading: downloadLoading, data: downloadData, error: downloadError },
  ] = useLazyQuery(download);
  if (error || downloadError) {
    return (
      <PageError
        message={
          (error && error.message) || (downloadError && downloadError.message)
        }
      />
    );
  }
  if (keepLoading) {
    return <PageLoading />;
  }
  if (data && data[queryName]) {
    const shapedData = dataShapeFunction(data[queryName]);
    return (
      <Fragment>
        {titleOnOwnLine && (
          <PageHeading
            heading={
              typeof heading === 'function' ? heading(data[queryName]) : heading
            }
            description={description}
          />
        )}
        <TopBar hasFilters={!!filterOptions} titleOnOwnLine={titleOnOwnLine}>
          {!titleOnOwnLine && (
            <PageHeading
              heading={
                typeof heading === 'function'
                  ? heading(data[queryName])
                  : heading
              }
              description={description}
            />
          )}
          {filterOptions && (
            <FilterOptions
              filterOptions={filterOptions}
              query={query}
              setQuery={setQuery}
              setSkip={setSkip}
            />
          )}
          <ActionRow
            actionLink={actionLink}
            actionLinkName={actionLinkName}
            initialLinkParamObject={initialLinkParamObject}
            noActions={noActions}
            download={download}
            downloadData={downloadData}
            downloadLoading={downloadLoading}
            downloadDataQuery={downloadDataQuery}
            query={query}
            order={order}
            headers={headings}
            gqlVariables={gqlVariables}
            dataShapeFunction={dataShapeFunction}
            queryName={queryName}
          />
        </TopBar>
        <PaginationContainer>
          <Pagination
            page={(take + skip) / take}
            pageSize={take}
            totalItems={
              paginationObject === queryName
                ? data[queryName].pagination.total
                : data[queryName][paginationObject].pagination.total
            }
            pageSizes={[10, 20, 30, 40, 50].concat(additionalPageSizes || [])}
            onChange={({ page, pageSize }) => {
              setTake(pageSize);
              setSkip((page - 1) * pageSize);
            }}
          />
        </PaginationContainer>
        <Table
          enter={enter}
          order={order}
          setOrder={setOrder}
          setSkip={setSkip}
          loading={loading || networkStatus === NetworkStatus.refetch}
          rows={shapedData}
          allData={shapedData}
          headers={headings}
          navLinks={navLinks || []}
          initialLinkParamObject={initialLinkParamObject}
          additionalLinkParam={additionalLinkParam}
          refetch={refetch}
          deleteRow={deleteRow}
          rawData={data[queryName]}
        />
      </Fragment>
    );
  }
  return <PageLoading />;
};

export default TableViewForGql;
