import AWS from 'aws-sdk';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { cognito, cognitoidentityserviceprovider } from './config';

const getUserPool = () => new CognitoUserPool(cognito);

// const cisp = () =>
//   new AWS.CognitoIdentityServiceProvider({
//     accessKeyId: 'AKIA5YK4DDJNMM335UOP',
//     secretAccessKey: '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',
//     region: 'eu-west-1',
//   });

const cisp = () =>
  new AWS.CognitoIdentityServiceProvider(cognitoidentityserviceprovider);

export { getUserPool, cisp };
