import { localStorageKeys } from '../config';
import { setCustomerPortalAuthToken } from '../connections';

const clearAllLocalStorageKeys = () => {
  setCustomerPortalAuthToken(null);
  Object.keys(localStorageKeys).forEach((key) => {
    localStorage.removeItem(localStorageKeys[key]);
  });
};

export default clearAllLocalStorageKeys;
