import { ArrowRight24 } from '@carbon/icons-react';
import { lighten } from 'polished';
import styled from 'styled-components';
import { PrimaryButton as Button } from '../../../../components';

const ForgotPassword = styled.div`
  padding: 10px;
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
  :hover {
    color: ${({ theme }) => lighten(0.1, theme.colors.primary)};
    cursor: pointer;
  }
`;

const FormArea = styled.div`
  margin-top: 20px;
  padding-bottom: 50px;
  width: 100%;
`;

const FormFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;
`;

const FormItem = styled.div`
  margin: 20px 0;
`;

const GridBox = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-auto-flow: column;
  width: 100%;
  margin-bottom: 50px;
`;

const LoginArrow = styled(ArrowRight24)`
  & path {
    fill: white;
  }
`;

const LoginButton = styled(Button)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: none !important;
  padding: 12px;
  max-width: 100%;
`;

export {
  ForgotPassword,
  FormArea,
  FormFooter,
  FormItem,
  GridBox,
  LoginArrow,
  LoginButton,
};
