import { noop } from 'lodash';
import React, { useEffect, useState } from 'react';
import { axios } from '../../connections';
import { PageContainer } from '../../styles';
import PageError from '../PageError';
import PageHeading from '../PageHeading';
import PageLoading from '../PageLoading';
import FilterOptions from './FilterOptions';
import { TopBar } from './styles';
import Table from './Table';

const TableView = ({
  heading,
  description,
  headers = [],
  api = noop,
  dataShapeFunction = (d) => d,
  onCompleted = noop,
  filterOptions = [],
  noPagination = false,
  initialLinkParamObject = {},
  additionalLinkParam = '',
}) => {
  const [rows, setRows] = useState();
  const [query, setQuery] = useState({});
  const take = 50;
  const [skip, setSkip] = useState(0);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const params = noPagination ? query : { ...query, take, skip };
    setLoading(true);
    axios
      .get(api(), { params })
      .then(({ data }) => {
        const modifiedData = dataShapeFunction(data);
        onCompleted(modifiedData);
        setRows(modifiedData);
      })
      .catch((e) => setError(e))
      .finally(() => setLoading(false));
  }, [api, dataShapeFunction, onCompleted, query, take, skip, noPagination]);

  if (error) {
    return <PageError message={error.message} />;
  }

  if (rows) {
    return (
      <PageContainer gridStyling={filterOptions.length && 'auto auto 1fr'}>
        <TopBar>
          <PageHeading heading={heading} description={description} />
        </TopBar>
        {filterOptions.length && (
          <FilterOptions
            filterOptions={filterOptions}
            setQuery={setQuery}
            setSkip={setSkip}
          />
        )}
        {!loading ? (
          <Table
            headers={headers}
            rows={rows}
            initialLinkParamObject={initialLinkParamObject}
            additionalLinkParam={additionalLinkParam}
          />
        ) : (
          <PageLoading />
        )}
      </PageContainer>
    );
  }

  return <PageLoading />;
};

export { default as TableViewForGql } from './TableViewForGql';
export { TableView as default, FilterOptions };
