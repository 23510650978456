import { format } from 'date-fns';
import { prepend } from 'ramda';
import React from 'react';
import { CSVDownload } from 'react-csv';
import Loading from '../../Spinner';
import { ActionButton, ActionRowContainer, DownloadIcon, Link } from './styles';

const ActionRow = ({
  actionLink,
  actionLinkName,
  noActions,
  download,
  headers,
  downloadData,
  downloadDataQuery,
  query,
  order,
  downloadLoading,
  gqlVariables,
  dataShapeFunction,
  queryName,
}) => {
  const downloadProps = {
    headers: prepend(
      { key: 'id', label: 'id' },
      headers.map(({ key, header }) => ({ key, label: header })),
    ),
    filename: `${format(new Date(), 'yyyy-MM-dd')}_trackmatic_asset_list.csv`,
  };
  return (
    <ActionRowContainer>
      {!noActions && (
        <Link to={actionLink}>
          <ActionButton>{actionLinkName}</ActionButton>
        </Link>
      )}
      {download && (
        <ActionButton
          onClick={() =>
            downloadDataQuery({
              variables: { query, order, ...(gqlVariables || {}) },
            })
          }
        >
          {downloadLoading ? (
            <Loading small withOverlay={false} />
          ) : (
            <DownloadIcon />
          )}
          {downloadData && (
            <CSVDownload
              target="_self"
              {...downloadProps}
              data={dataShapeFunction(downloadData[queryName])}
            />
          )}
        </ActionButton>
      )}
    </ActionRowContainer>
  );
};

export default ActionRow;
