import generateLinkWithParams from '../functions/generateLinkWithParams';
//could be something to do with a circular reference

const linkPlaceholders = {
  customerId: ':customerId',
  consignmentId: ':consignmentId',
};

const localStorageKeys = {
  idToken: 'TM_CUSTOMER_PORTAL_ID_TOKEN',
  rememberMe: 'TM_CUSTOMER_PORTAL_REMEMBER_ME',
};

const entityTypes = {
  customer: 'CUSTOMER',
};

const customers = {
  root: `/customers`,
};

const customerRoot = `${customers.root}/${linkPlaceholders.customerId}`;

const customer = {
  root: `${customerRoot}`,
  printEpod: `${customerRoot}/epod/${linkPlaceholders.consignmentId}/print`,
  summary: `${customerRoot}/summary`,
  transporterDashboard: `${customerRoot}/transporter_dashboard`,
};

const routing = {
  customers,
  customer,
};

const apis = {
  bootstrap: (customerId) => `loads/shippers/${customerId}/customer/bootstrap`,
  portal: (customerId) => `loads/shippers/${customerId}/customer/portal`,
  getPlan: (orgId, loadId) =>
    `tracking-generic/${orgId}/plans/${loadId}/extrapolation`,
  getConsignment: (customerId, consignmentId) =>
    `loads/shippers/${customerId}/customer/byconsignmentId/${consignmentId}`,
};

const sideNav = {
  tm: {
    customers: {
      name: 'Customers',
      link: generateLinkWithParams(routing.customers.root),
    },
  },
  customer: {
    landingPage: {
      name: 'Orders',
      link: generateLinkWithParams(routing.customer.root),
    },
    summary: {
      name: 'Summary',
      link: generateLinkWithParams(routing.customer.summary),
    },
    transporterDashboard: {
      name: 'Transporter Dashboard',
      link: generateLinkWithParams(routing.customer.transporterDashboard),
    },
  },
};

export {
  localStorageKeys,
  apis,
  routing,
  entityTypes,
  sideNav,
  linkPlaceholders,
};
