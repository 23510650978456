import React from 'react';
import { LoginContext, LoginProvider as Provider } from './LoggedInUserContext';
import LoginProcess from './LoginProcess';

const LoginProvider = ({ children }) => {
  return (
    <LoginProcess>
      <Provider>{children}</Provider>
    </LoginProcess>
  );
};

export { LoginProvider, LoginContext };
