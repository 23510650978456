import { CognitoUser } from 'amazon-cognito-identity-js';
import { Field, Form, Formik } from 'formik';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { ThemeContext } from 'styled-components';
import {
  ActionButtons,
  InlineNotification,
  PasswordFormField,
  PrimaryButton,
  SecondaryButton,
  TextFormField,
} from '../../../../components';
import { cognito } from '../../../../connections';
import { LoadingContext } from '../../../../context';
import { CheckBox, EmptyCheckBox, FormItem } from '../../../../styles';
import Banner from '../Banner';
import { ForgotPassword, FormArea, GridBox } from '../LoginForm/styles';
import { step1, step2 } from './initialValues';

const SetPasswordForm = ({ username, updateResetPassword, setNotify }) => {
  const reload = () => {
    setTimeout(() => {
      window.location.href = '/';
    }, 2000);
  };
  const { setLoading } = useContext(LoadingContext);
  const theme = useContext(ThemeContext);
  const [passwordInput, setPasswordInput] = useState('');
  const [passwordValid, setPasswordValid] = useState({
    uppercase: false,
    lowercase: false,
    special: false,
    number: false,
    goodLength: false,
  });
  const [auth, updateAuth] = useState('');
  const [user, setUser] = useState(username);
  const [step, updateStep] = useState(1);
  const [cognitoUser, setCognitoUser] = useState();

  useEffect(() => {
    const lowercase = /[a-z]/.test(passwordInput);
    const uppercase = /[A-Z]/.test(passwordInput);
    const special = /[!@#$%^&*+=._-]/.test(passwordInput);
    const number = /[0-9]/.test(passwordInput);
    const goodLength = passwordInput.length >= 8;
    const validationPayload = {
      uppercase,
      lowercase,
      special,
      number,
      goodLength,
    };
    setPasswordValid(validationPayload);
  }, [passwordInput]);

  const sendPIN = (usr) => {
    usr.forgotPassword({
      onSuccess: () => {
        setLoading(false);
        updateStep(2);
      },
      onFailure: (err) => {
        setLoading(false);
        setNotify(
          <InlineNotification
            kind="error"
            title="Error"
            subtitle={err.message}
          />,
        );
      },
    });
  };

  const resetPassword = () => {
    cognitoUser.confirmPassword(auth, passwordInput, {
      onSuccess: () => {
        setNotify(
          <InlineNotification
            kind="success"
            title="Password Changed"
            subtitle="Your password has been updated"
          />,
        );
        reload();
      },
      onFailure: function (err) {
        setLoading(false);
        setNotify(
          <InlineNotification
            kind="error"
            title="Error"
            subtitle={err.message}
          />,
        );
      },
    });
  };

  const resetForm = () => {
    setLoading(false);
    updateResetPassword(false);
  };

  const stepSubtext = () =>
    step === 1
      ? 'Enter in your email address to receive a reset code'
      : step === 2
      ? 'Enter in the reset code you received'
      : 'Enter in your new password';

  return (
    <Fragment>
      <Banner text="Password Reset" subtext={stepSubtext()} />
      <FormArea columns="1" fitWidth>
        {step === 1 && (
          <Formik
            initialValues={{ email: user }}
            validationSchema={step1.validationSchema}
            onSubmit={({ email }) => {
              setLoading(true);
              setUser(email);
              const cgUser = new CognitoUser({
                Username: email,
                Pool: cognito(),
              });
              setCognitoUser(cgUser);
              sendPIN(cgUser);
            }}
          >
            {({ isValid }) => (
              <Form>
                <FormItem>
                  <Field
                    labelText="Email Address"
                    name="email"
                    type="text"
                    component={TextFormField}
                    light
                  />
                </FormItem>
                <div style={{ marginBottom: '150px' }} />
                <ActionButtons
                  type="finalFormButton"
                  disabled={!isValid}
                  fillWidth
                  primaryName="Send Reset Code"
                />
              </Form>
            )}
          </Formik>
        )}
        {step === 2 && (
          <Formik
            initialValues={step2.initialValues}
            validationSchema={step2.validationSchema}
            onSubmit={({ code }) => {
              updateAuth(code);
              updateStep(3);
            }}
          >
            <Form>
              <FormItem>
                <Field
                  placeholder="One-time PIN"
                  name="code"
                  type="text"
                  component={TextFormField}
                  light
                />
              </FormItem>
              <div style={{ marginBottom: '150px' }} />
              <ActionButtons
                type="finalFormButton"
                fillWidth
                primaryName="Next"
              />
            </Form>
          </Formik>
        )}
        {step === 3 && (
          <form>
            <FormItem>
              <PasswordFormField
                value={passwordInput}
                onChange={(e) => {
                  setPasswordInput(e.target.value);
                }}
                labelText="Password"
                showPasswordLabel="Show"
                hidePasswordLabel="Hide"
                name="pw"
                light
              />
            </FormItem>
            <p style={{ paddingTop: '20px', textAlign: 'center' }}>
              Password must include at least one of each of the following:
            </p>
            <GridBox>
              {[
                'Uppercase Letter',
                'Lowercase Letter',
                'Special Character',
                'Number',
                'Length of 8 Characters',
              ].map((val, idx) => {
                const isValid = passwordValid[Object.keys(passwordValid)[idx]];
                return (
                  <p
                    key={idx}
                    style={{
                      color: isValid
                        ? theme.colors.primary
                        : theme.colors.darkGrey,
                    }}
                  >
                    {isValid ? <CheckBox /> : <EmptyCheckBox />}
                    {val}
                  </p>
                );
              })}
            </GridBox>
            <PrimaryButton
              type="button"
              fillWidth
              disabled={!Object.values(passwordValid).every((v) => v)}
              onClick={() => {
                setLoading(true);
                resetPassword();
              }}
            >
              Reset Password
            </PrimaryButton>
          </form>
        )}
        <SecondaryButton fillWidth onClick={resetForm}>
          Cancel
        </SecondaryButton>
        {step === 2 && (
          <ForgotPassword
            onClick={() => {
              setLoading(true);
              sendPIN(cognitoUser);
            }}
          >
            Resend One-Time PIN
          </ForgotPassword>
        )}
      </FormArea>
    </Fragment>
  );
};

export default SetPasswordForm;
