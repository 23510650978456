import styled, { css } from 'styled-components';
import { darken, lighten } from 'polished';

const TableBodyContainer = styled.div`
  height: 76vh;
  width: 100%;
  overflow: auto;
`;
const Table = styled.table`
  border-collapse: separate;
  border-spacing: 0;
  box-sizing: border-box;
  width: 100%;
  & thead tr {
    background: ${({ theme }) => lighten(0.1, theme.colors.lightGrey)};
  }
  & tbody {
    overflow: hidden;
  }
  & td {
    text-align: left;
    border-bottom: 1px solid;
    border-right: 1px solid;
    :first-child {
      border-left: 1px solid;
      border-color: ${({ theme }) => theme.colors.lightGrey};
    }
    border-color: ${({ theme }) => theme.colors.lightGrey};
    vertical-align: middle;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: ${({ theme }) => theme.colors.darkGrey};
  }
  & td > * {
    padding: 0.3rem 1rem;
    width: 100%;
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  & tr {
    height: 0.8rem;
  }
  & .bx--dropdown,
  & .bx--list-box,
  & .bx--combo-box,
  & .bx--text-input {
    border-bottom: none;
  }
  & .bx--text-input {
    border-bottom: none !important;
  }
`;

const Th = styled.th`
  position: sticky;
  top: 0;
  z-index: 1;
  background: ${({ theme }) => theme.colors.lightGrey};
  color: ${({ theme }) => theme.colors.darkGrey};
  text-align: left;
  border-bottom: 2px solid;
  border-right: 2px solid;
  border-top: 2px solid;
  border-color: ${({ theme }) => lighten(0.3, theme.colors.mediumGrey)};
  :first-child {
    border-left: 2px solid;
    border-color: ${({ theme }) => lighten(0.3, theme.colors.mediumGrey)};
  }
  height: 100%;
  vertical-align: middle;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 800;
  font-size: 0.75rem;
  padding: 0.75rem 1rem;
  ${({ noHover, theme }) =>
    !noHover &&
    css`
      :hover {
        background: ${darken(0.2, theme.colors.lightGrey)};
      }
    `}
`;

export { Table as default, Th, TableBodyContainer };
