import styled from 'styled-components';

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const LoginContainer = styled.div`
  padding: 1.5rem;
  width: 35%;
  max-width: 450px;
  height: 65%;
  box-shadow: 0 0 5px 0 ${({ theme }) => theme.colors.mediumGrey};
`;

export { Container, LoginContainer };
