import styled from 'styled-components';

const PaginationContainer = styled.div`
  & * {
    font-size: 0.7rem !important;
  }
  & div {
    /* background: ${({ theme }) => theme.colors.white}; */
  }
  & *:focus {
    outline: none !important;
    border-color: ${({ theme }) => theme.colors.lightGrey} !important;
    box-shadow: inset 0 0 0 1px ${({ theme }) => theme.colors.lightGrey} !important;
  }
  & .bx--btn {
    min-height: 0;
  }
`;

export default PaginationContainer;
