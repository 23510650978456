import React, { createContext, useState } from 'react';
import { Modal } from '../components';

const ModalContext = createContext();

const ModalProvider = ({ children }) => {
  const [isModalOpen, toggleModalHandler] = useState(false);
  const [modalContent, setModalContent] = useState();
  const [dimensions, setDimensions] = useState({});
  const toggleModal = () => toggleModalHandler((prev) => !prev);
  const clearModal = () => {
    setDimensions({});
    toggleModalHandler(false);
    setModalContent(null);
  };
  return (
    <ModalContext.Provider
      value={{ toggleModal, setModalContent, setDimensions, clearModal }}
    >
      <Modal {...dimensions} open={isModalOpen} close={clearModal}>
        {modalContent}
      </Modal>
      {children}
    </ModalContext.Provider>
  );
};

export { ModalContext, ModalProvider };
