import { darken } from 'polished';
import React from 'react';
import styled from 'styled-components';
import {
  CaretSort20,
  CaretSortDown20,
  CaretSortUp20,
} from '@carbon/icons-react';
import { Th } from '../../../styles';

const TableHeader = ({
  className,
  allowOrder,
  heading,
  id,
  order,
  setOrder,
  setSkip,
}) => {
  const currentOrder = order[0];
  const Caret =
    !currentOrder || (currentOrder && currentOrder[0] !== id)
      ? CaretSort20
      : currentOrder[1] === 'ASC'
      ? CaretSortDown20
      : CaretSortUp20;
  return (
    <Th
      className={className}
      onClick={() => {
        setSkip(0);
        if (!currentOrder || (currentOrder && currentOrder[0] !== id)) {
          setOrder([[id, 'ASC']]);
        } else if (currentOrder[1] === 'ASC') {
          setOrder([[id, 'DESC']]);
        } else {
          setOrder([]);
        }
      }}
    >
      <div>
        <p>{heading}</p>
        <Caret />
      </div>
    </Th>
  );
};

export default styled(TableHeader)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.darkGrey};
  text-align: left;
  height: 100%;
  vertical-align: middle;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 800;
  padding: 0.75rem 1rem;
  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  & p {
    font-size: 0.75rem;
    font-weight: 800;
    vertical-align: middle;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  :hover {
    background: ${({ theme }) => darken(0.2, theme.colors.lightGrey)};
  }
`;
