import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Dropdown, PageError } from '../../components';
import { apis } from '../../config';
import { axios } from '../../connections';
import {
  LoadingContext,
  LoginContext,
  RightSideViewContext,
} from '../../context';
import { formatDashDateTime, listSortAndFormat } from '../../functions';
import { Table, Th } from '../../styles';
import {
  DropdownContainer,
  KPIBlock,
  KPILine,
  RightSideCard,
  RightSideContainer,
  Status,
  SummaryCard,
  SummaryContainer,
} from './styles';

const TransporterDashboard = () => {
  const { currentEntity } = useContext(LoginContext);
  const { setIsRightSideViewOpen } = useContext(RightSideViewContext);
  const { setLoading } = useContext(LoadingContext);
  const [payload, setPayload] = useState([]);
  const [error, setError] = useState();
  const [currentOrg, setCurrentOrg] = useState(
    currentEntity.organisations[0].id,
  );

  useEffect(() => {
    setIsRightSideViewOpen('60% 40%');
    return () => {
      setIsRightSideViewOpen(false);
    };
  }, [setIsRightSideViewOpen]);

  useEffect(() => {
    if (currentOrg) {
      setLoading(true);
      axios
        .get(apis.portal(currentEntity.id), {
          params: { organisationId: currentOrg },
        })
        .then(({ data }) => {
          setPayload(data.data.map((d) => d.data));
        })
        .catch((e) => setError(e))
        .finally(() => {
          setLoading(false);
        });
    }
  }, [currentEntity.id, currentOrg, setLoading]);

  if (error) {
    return <PageError message={error.message} />;
  }

  const deliveries = payload.length;
  const statuses = [
    'Unallocated',
    'Scheduled',
    'Active',
    'Complete',
    'Failed',
    'Unactioned',
  ].map((status) => {
    const dropCount = payload.filter((p) => p.status === status).length;
    return {
      status,
      dropCount,
      dropPercent:
        deliveries === 0 ? 0 : Math.round((dropCount / deliveries) * 100),
    };
  });

  const rightSideData = ['Complete', 'Failed'].map((status) => ({
    status,
    drops: payload.filter((p) => p.status === status),
  }));

  return (
    <Fragment>
      <SummaryContainer>
        <h2>Transporter Dashboard</h2>
        <DropdownContainer>
          <Dropdown
            options={listSortAndFormat(
              currentEntity.organisations,
              'name',
              'id',
            )}
            value={currentOrg}
            onChange={({ value }) => {
              setCurrentOrg(value);
            }}
          />
        </DropdownContainer>
        <KPILine>
          <KPIBlock>
            <h2>Deliveries</h2>
            <h1>{deliveries}</h1>
          </KPIBlock>
        </KPILine>
        <SummaryCard>
          {statuses.map(({ status, dropPercent, dropCount }) => (
            <Status key={status} status={status}>
              <div>
                <h1>{dropPercent}%</h1>
                <h2>{dropCount}</h2>
              </div>
              <h2>{status}</h2>
            </Status>
          ))}
        </SummaryCard>
      </SummaryContainer>
      <RightSideContainer>
        {rightSideData.map(({ status, drops }) => (
          <RightSideCard key={status}>
            <h3>Deliveries {status}</h3>
            <div style={{ overflow: 'auto' }}>
              <Table>
                <thead>
                  <tr>
                    <Th noHover>Order Number</Th>
                    <Th noHover>Delivery Note</Th>
                    <Th noHover>Time {status}</Th>
                    <Th noHover>Customer</Th>
                  </tr>
                </thead>
                <tbody>
                  {drops.map((drop) => (
                    <tr key={drop.id}>
                      <td>
                        <p>{drop.orderNo}</p>
                      </td>
                      <td>
                        <p>{drop.deliveryNote}</p>
                      </td>
                      <td>
                        <p>{formatDashDateTime(drop.lastStatusChangeDate)}</p>
                      </td>
                      <td>
                        <p>{drop.consigneeName}</p>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </RightSideCard>
        ))}
      </RightSideContainer>
    </Fragment>
  );
};

export default TransporterDashboard;
