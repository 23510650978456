import React, { useContext, useState, Fragment, useEffect } from 'react';
import { withRouter } from 'react-router';
import { LoginContext, SideNavContext } from '../../../context';
import { Container, MenuBlock, MenuIcon, MenuLink, MenuTop } from './styles';

const SideNav = withRouter(({ location }) => {
  const { pathname } = location;
  const { linkParams } = useContext(LoginContext);
  const [openMenuOptions, setOpenMenuOptions] = useState([]);
  const { isSideNavOpen, toggleSideNav, sideNavOptions } =
    useContext(SideNavContext);

  useEffect(() => {
    setOpenMenuOptions([]);
  }, [sideNavOptions]);

  const determineActive = (option) => {
    const link = option.link && option.link(linkParams);
    if (link) {
      return !link.includes(pathname) ? false : pathname.includes(link);
    }
    return false;
  };

  const mapOptions = (option, level = 0) => {
    return Object.values(option).map((o) => {
      const isOpen = openMenuOptions.some((omo) => omo === o.openId);
      const isActive = determineActive(o);
      return (
        <MenuBlock level={level} active={isActive}>
          {o.link ? (
            <MenuLink level={level} to={o.link(linkParams)} active={isActive}>
              {o.name}
            </MenuLink>
          ) : (
            <Fragment>
              <MenuTop
                onClick={() => {
                  setOpenMenuOptions((prev) => {
                    if (prev.includes(o.openId)) {
                      return prev.filter((p) => p !== o.openId);
                    } else {
                      return [...prev, o.openId];
                    }
                  });
                }}
                isOpen={isOpen}
                level={level}
                active={isActive}
              >
                {o.name}
              </MenuTop>
              {isOpen && mapOptions(o.sub, level + 1)}
            </Fragment>
          )}
        </MenuBlock>
      );
    });
  };
  return (
    <Container isOpen={isSideNavOpen}>
      <MenuIcon onClick={toggleSideNav} />
      {isSideNavOpen && mapOptions(sideNavOptions)}
    </Container>
  );
});

export default SideNav;
