import { localStorageKeys } from '../config';
import { http } from './config';
import {
  InMemoryCache,
  HttpLink,
  ApolloClient,
  ApolloLink,
  from,
} from '@apollo/client';

const httpLink = new HttpLink(http);

const authMiddleware = new ApolloLink((operation, forward) => {
  const authHeader = localStorage.getItem(localStorageKeys.idToken)
    ? { authorization: localStorage.getItem(localStorageKeys.idToken) }
    : {};
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      ...authHeader,
    },
  }));
  return forward(operation);
});

const cache = new InMemoryCache({
  addTypename: false,
});

const client = new ApolloClient({
  link: from([authMiddleware, httpLink]),
  cache,
  defaultOptions: {
    query: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
    },
    watchQuery: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
  },
});

export default client;
