import React, { Fragment, useContext } from 'react';
import {
  LoginContext,
  ModalContext,
  NotificationsContext,
} from '../../../context';
import {
  convertEnumString,
  encrypt,
  formatCurrency,
  formatDateTime,
  generateLinkWithParams,
} from '../../../functions';
import {
  EditLink,
  EmailLink,
  Link,
  Table,
  TableBodyContainer,
  Th,
} from '../../../styles';
import Loading from '../../Spinner';
import DeleteRowModal from './Delete';
import {
  BooleanContainer,
  Color,
  Delete,
  Enter,
  False,
  NoData,
  True,
} from './styles';
import TableHeader from './TableHeader';

const TableComponent = ({
  headers = [],
  rows = [],
  initialLinkParamObject = {},
  additionalLinkParam = '',
  loading,
  order,
  setOrder,
  setSkip,
  enter,
  refetch,
  deleteRow,
  rawData,
  navLinks = [],
}) => {
  const { toggleModal, setModalContent, setDimensions } =
    useContext(ModalContext);
  const close = () => {
    toggleModal();
    setModalContent();
    setDimensions({});
  };
  const notificationsContext = useContext(NotificationsContext);
  const { currentUser, currentEntity } = useContext(LoginContext);
  return (
    <TableBodyContainer>
      <Table>
        <colgroup>
          {enter && <col width="2%" />}
          {headers.map((header, idx) => (
            <col key={idx} width={header.isCount ? '7.5%' : 'auto'} />
          ))}
          {navLinks.map((_, idx) => (
            <col key={idx} width={`7.5%`} />
          ))}
          {deleteRow && <col width={`2%`} />}
        </colgroup>
        <thead>
          <tr>
            {enter && <Th noHover />}
            {headers.map((header) =>
              header.allowOrder ? (
                <TableHeader
                  order={order}
                  setOrder={setOrder}
                  setSkip={setSkip}
                  heading={header.header}
                  key={header.key}
                  id={header.key}
                />
              ) : (
                <Th
                  noHover
                  key={header.header}
                  style={{
                    textAlign: header.isCount ? 'center' : 'left',
                  }}
                >
                  {header.header}
                </Th>
              ),
            )}
            {navLinks.map(() => (
              <Th noHover />
            ))}
            {deleteRow && <Th noHover />}
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <NoData
              style={{
                display: 'flex',
              }}
            >
              Loading Data... <Loading withOverlay={false} small />
            </NoData>
          ) : rows.length > 0 ? (
            rows.map((row) => {
              const linkParam = { ...initialLinkParamObject };
              if (Array.isArray(additionalLinkParam)) {
                const allRowData = rows.find((r) => r.id === row.id);
                additionalLinkParam.forEach(({ param, value }) => {
                  linkParam[param] = allRowData[value];
                });
              } else {
                linkParam[additionalLinkParam] = row.id;
              }
              return (
                <tr key={row.id}>
                  {enter && (
                    <td>
                      <EditLink to={generateLinkWithParams(enter, linkParam)}>
                        <BooleanContainer clickable>
                          <Enter />
                        </BooleanContainer>
                      </EditLink>
                    </td>
                  )}
                  {headers.map((headingsPayload) => {
                    if (headingsPayload.QrCode) {
                      return (
                        <td key={headingsPayload.key}>
                          <headingsPayload.QrCode
                            rawData={rawData}
                            row={row}
                            value={row[headingsPayload.key]}
                          />
                        </td>
                      );
                    }
                    if (headingsPayload.componentType) {
                      const displayModalOption = headingsPayload.condition
                        ? headingsPayload.condition(row, rawData)
                        : true;
                      if (headingsPayload.componentType === 'modal') {
                        return (
                          <td key={headingsPayload.key}>
                            {displayModalOption && (
                              <headingsPayload.ComponentWrapper
                                value={row[headingsPayload.key]}
                                rawData={rawData}
                                row={row}
                                onClick={() => {
                                  headingsPayload.dimensions &&
                                    setDimensions(headingsPayload.dimensions);

                                  setModalContent(
                                    <Fragment>
                                      <headingsPayload.Component
                                        value={row[headingsPayload.key]}
                                        row={row}
                                        close={close}
                                        reloadTableData={refetch}
                                        rawData={rawData}
                                        currentUser={currentUser}
                                        currentEntity={currentEntity}
                                        notificationsContext={
                                          notificationsContext
                                        }
                                      />
                                    </Fragment>,
                                  );
                                  toggleModal();
                                }}
                              ></headingsPayload.ComponentWrapper>
                            )}
                          </td>
                        );
                      }

                      if (headingsPayload.componentType === 'color') {
                        return (
                          <Color
                            key={headingsPayload.key}
                            color={row[headingsPayload.key]}
                          >
                            <div />
                          </Color>
                        );
                      }

                      if (headingsPayload.componentType === 'link') {
                        return (
                          <td key={headingsPayload.key}>
                            {displayModalOption && (
                              <Link
                                to={generateLinkWithParams(
                                  headingsPayload.componentLink,
                                  linkParam,
                                )}
                                target="_blank"
                              >
                                <headingsPayload.ComponentWrapper />
                              </Link>
                            )}
                          </td>
                        );
                      }
                    }
                    if (headingsPayload.encrypt) {
                      return (
                        <td>
                          <p>{encrypt(row[headingsPayload.key])}</p>
                        </td>
                      );
                    }
                    if (typeof row[headingsPayload.key] === 'boolean') {
                      return (
                        <td>
                          <BooleanContainer
                            style={{
                              display: 'flex',
                            }}
                          >
                            {row[headingsPayload.key] ? <True /> : <False />}
                          </BooleanContainer>
                        </td>
                      );
                    }
                    return (
                      <td key={headingsPayload.key}>
                        {headingsPayload.key === 'email' ? (
                          <EmailLink
                            href={`mailto:${row[headingsPayload.key]}`}
                            target="_blank"
                          >
                            {row[headingsPayload.key]}
                          </EmailLink>
                        ) : (
                          <p
                            style={{
                              textAlign: headingsPayload.isCount
                                ? 'center'
                                : 'left',
                              color:
                                headingsPayload.color &&
                                headingsPayload.color(row, rawData),
                            }}
                          >
                            {headingsPayload.isEnum
                              ? convertEnumString(row[headingsPayload.key])
                              : headingsPayload.isDateTime
                              ? formatDateTime(row[headingsPayload.key])
                              : headingsPayload.isCurrency
                              ? formatCurrency(row[headingsPayload.key])
                              : row[headingsPayload.key]}
                          </p>
                        )}
                      </td>
                    );
                  })}
                  {navLinks.map(({ name, link, display, newTab }) => {
                    return (
                      <td key={name}>
                        {(!display || display(row)) && (
                          <EditLink
                            style={{ justifyContent: 'center' }}
                            to={link(linkParam)}
                            target={newTab ? '_blank' : ''}
                          >
                            {name}
                          </EditLink>
                        )}
                      </td>
                    );
                  })}
                  {deleteRow && (
                    <td>
                      <BooleanContainer clickable>
                        <Delete
                          onClick={() => {
                            setModalContent(
                              <Fragment>
                                <DeleteRowModal
                                  id={row.id}
                                  close={close}
                                  deleteRow={deleteRow}
                                  refetch={refetch}
                                />
                              </Fragment>,
                            );
                            toggleModal();
                          }}
                        />
                      </BooleanContainer>
                    </td>
                  )}
                </tr>
              );
            })
          ) : (
            <NoData>No data yet...</NoData>
          )}
        </tbody>
      </Table>
    </TableBodyContainer>
  );
};

export default TableComponent;
