import styled from 'styled-components';

const SummaryContainer = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: repeat(3, auto) repeat(1, 1fr);
  grid-row-gap: 1rem;
  overflow: auto;
`;

const DropdownContainer = styled.div`
  height: 3rem;
  width: 30%;
`;

const SummaryCard = styled.div`
  background: ${({ theme }) => theme.colors.lightGrey};
  width: 100%;
  height: 100%;
  padding: 0.5rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  align-items: center;
  justify-items: center;
`;

const Header = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  & * {
    text-align: center;
  }
  & h1 {
    font-size: 4rem;
  }
`;

const Status = styled.div`
  width: 100%;
  height: 100%;
  & h1 {
    font-size: 5rem;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: ${({ width }) => width};
  font-size: 0.65rem;
  font-weight: bold;
  text-align: center;
  > h2 {
    font-size: 1.3rem;
    border-bottom: 5px solid
      ${({ theme, status }) =>
        theme.colors.statuses[status] || theme.colors.primary};
    padding-bottom: 0.5rem;
  }
`;

const RightSideContainer = styled.div`
  background: ${({ theme }) => theme.colors.lightGrey};
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-row-gap: 1rem;
  overflow: auto;
`;

const RightSideCard = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-row-gap: 1rem;
  & th,
  td p {
    font-size: 0.6rem;
    padding: 0.5rem 0.2rem;
  }
  & td p {
    padding: 0.2rem 0.2rem;
  }
`;

const KPIBlock = styled(Header)`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.lightGrey};
  & h1 {
    font-size: 6rem;
  }
  padding: 1rem;
`;

const KPILine = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 1rem;
`;

export {
  SummaryContainer,
  SummaryCard,
  Status,
  DropdownContainer,
  RightSideContainer,
  RightSideCard,
  KPILine,
  KPIBlock,
};
