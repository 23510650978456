import React from 'react';
import styled from 'styled-components';
import { lighten } from 'polished';

const Button = ({
  onClick,
  children,
  className,
  style,
  type,
  fillWidth,
  disabled,
}) => {
  return (
    <button
      disabled={disabled}
      type={type}
      className={className}
      onClick={onClick}
      style={{ ...style }}
    >
      {children}
    </button>
  );
};

const StyledButton = styled(Button)`
  border: none;
  width: ${({ fillWidth, oneButton, width, theme }) =>
    fillWidth ? '100%' : width || theme.formColumnWidth};
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  background: ${({ theme }) => theme.colors.primary};
  :hover {
    background: ${({ theme }) => lighten(0.1, theme.colors.primary)};
  }
  :disabled {
    background: ${({ theme }) => theme.colors.disabled};
    cursor: not-allowed;
  }
  color: ${({ theme }) => theme.colors.white};
  & a {
    color: ${({ theme }) => theme.colors.white};
    width: 100%;
    height: 100%;
  }
  cursor: pointer;
`;
const BasicButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${({ border }) => border || 'none'};
  outline: none;
  background: ${({ background }) => background || 'inherit'};
  color: ${({ color, theme }) => color || theme.colors.primary};
  :hover {
    color: ${({ theme, color }) =>
      color ? lighten(0.1, color) : lighten(0.1, theme.colors.buttonSecondary)};
    cursor: pointer;
  }
`;

const PrimaryButton = StyledButton;
const PrimaryButtonModal = styled(PrimaryButton)`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 50%;
`;

const SecondaryButton = styled(StyledButton)`
  background: ${({ theme }) => theme.colors.buttonSecondary};
  :hover {
    background: ${({ theme }) => lighten(0.1, theme.colors.buttonSecondary)};
  }
  :disabled {
    background: ${({ theme }) => lighten(0.1, theme.colors.buttonSecondary)};
  }
`;
const SecondaryButtonModal = styled(SecondaryButton)`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 50%;
`;

const SuccessButton = styled(StyledButton)`
  background: ${({ theme }) => theme.colors.success};
  :hover {
    background: ${({ theme }) => lighten(0.1, theme.colors.success)};
  }
`;

const DangerButton = styled(StyledButton)`
  background: ${({ theme }) => theme.colors.danger};
  :hover {
    background: ${({ theme }) => lighten(0.1, theme.colors.danger)};
  }
  :disabled {
    background: ${({ theme }) => lighten(0.1, theme.colors.buttonSecondary)};
  }
`;

const WarningButton = styled(StyledButton)`
  background: ${({ theme }) => theme.colors.warning};
  :hover {
    background: ${({ theme }) => lighten(0.1, theme.colors.warning)};
  }
`;

const BrokenButton = styled(StyledButton)`
  background: ${({ theme }) => theme.colors.broken};
  :hover {
    background: ${({ theme }) => lighten(0.1, theme.colors.broken)};
  }
`;

export {
  PrimaryButton,
  PrimaryButtonModal,
  SecondaryButton,
  SecondaryButtonModal,
  SuccessButton,
  DangerButton,
  WarningButton,
  BrokenButton,
  BasicButton,
};
