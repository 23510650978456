import styled from 'styled-components';

const Container = styled.div`
  background: ${({ theme }) => theme.colors.lightGrey};
  text-align: center;
  display: grid;
  grid-template-rows: auto 1fr;
  align-items: center;
  > h2 {
    margin-bottom: 1rem;
  }
`;

const StatusBox = styled.div`
  background: ${({ theme }) => theme.colors.white};
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  width: 80%;
  height: 100%;
  padding: 0.5rem;
  padding-top: 1rem;
  display: grid;
  grid-template-rows: auto 1fr;
  align-items: center;
  & h2 {
    font-size: 2rem;
  }
  position: relative;
  ::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 10px;
    background: ${({ theme, status }) =>
      theme.colors.statuses[status] || theme.colors.primary};
  }
`;

const StatusBoxContainer = styled.div`
  display: grid;
  height: 100%;
  grid-template-rows: repeat(6, 1fr);
  align-items: center;
  justify-items: center;
  grid-row-gap: 0.5rem;
`;

export { Container, StatusBox, StatusBoxContainer };
