import { get } from 'lodash';
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { PageLoading, TableView } from '../../components';
import { apis, linkPlaceholders } from '../../config';
import { LoginContext, RightSideViewContext } from '../../context';
import { listSortAndFormat } from '../../functions';
import DeliveryStatusSideBar from '../DeliveryStatusSideBar';
import headers from './headers';

const LandingPage = () => {
  const { currentEntity, linkParams } = useContext(LoginContext);
  const { setIsRightSideViewOpen } = useContext(RightSideViewContext);
  const [payload, setPayload] = useState([]);
  const filterOptions = [
    {
      type: 'input',
      name: 'term',
      placeholder: 'Term',
    },
    {
      type: 'dropdown',
      name: 'organisationId',
      options: listSortAndFormat(currentEntity.organisations, 'name', 'id'),
      placeholder: 'Organisation',
    },
    {
      placeholder: 'Status',
      type: 'dropdown',
      name: 'status',
      options: listSortAndFormat(
        [
          { name: 'Unallocated' },
          { name: 'Allocated' },
          { name: 'Complete' },
          { name: 'Failed' },
        ],
        'name',
        'name',
      ),
    },
  ];

  const api = useCallback(
    () => apis.portal(currentEntity.id),
    [currentEntity.id],
  );

  const onCompleted = useCallback((d) => setPayload(d), []);

  useEffect(() => {
    setIsRightSideViewOpen(true);
    return () => {
      setIsRightSideViewOpen(false);
    };
  }, [setIsRightSideViewOpen]);

  const dataShapeFunction = useCallback(({ data }) => {
    return data.map((d) => {
      const { name, surname } = get(d.data, 'load.driver', {});
      return {
        ...d.data,
        vehicle: get(d.data, 'load.asset.registrationNo', ''),
        driver: `${name || ''} ${surname || ''}`,
        reference: get(d.data, 'load.reference', ''),
      };
    });
  }, []);

  if (!currentEntity.id) {
    return <PageLoading />;
  }

  return (
    <Fragment>
      <TableView
        heading="Customer Portal Orders"
        description="View Orders"
        headers={headers}
        api={api}
        dataShapeFunction={dataShapeFunction}
        onCompleted={onCompleted}
        filterOptions={filterOptions}
        noPagination
        initialLinkParamObject={linkParams}
        additionalLinkParam={linkPlaceholders.consignmentId}
      />
      <DeliveryStatusSideBar payload={payload} />
    </Fragment>
  );
};

export default LandingPage;
