import React, { useContext } from 'react';
import ConfirmModal from '../../ConfirmModal';
import { useMutation } from '@apollo/client';
import { NotificationsContext } from '../../../context';

const Delete = ({ id, deleteRow, close, refetch }) => {
  const { addInlineAlert } = useContext(NotificationsContext);
  const [deleteRowMutation, { loading }] = useMutation(deleteRow, {
    onCompleted: () => {
      addInlineAlert('success', 'Deleted', `Row has been deleted.`);
      refetch();
      close();
    },
    onError: (err) => {
      addInlineAlert('error', 'Error', err.message);
      close();
    },
  });
  return (
    <ConfirmModal
      danger
      title="DELETE ROW"
      subtitle="Are you sure?"
      onCancel={close}
      onConfirm={() => deleteRowMutation({ variables: { id } })}
      loading={loading}
    />
  );
};

export default Delete;
