import styled from 'styled-components';

const ModalBackdrop = styled.div`
  position: fixed;
  z-index: 2;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalContainer = styled.div`
  position: fixed;
  z-index: 3;
  padding: 1rem;
  width: ${({ width }) => (width ? `${width}%` : '50%')};
  height: ${({ height }) => (height ? `${height}%` : '50%')};
  left: ${({ width }) => (width ? `${(100 - width) / 2}%` : '25%')};
  top: ${({ height }) => (height ? `${(100 - height) / 2}%` : '25%')};
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Exit = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
`;

export { ModalBackdrop, ModalContainer, Exit };
