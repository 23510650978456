import styled, { css } from 'styled-components';
import { CaretLeft16, CaretRight16 } from '@carbon/icons-react';
import React from 'react';

const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr auto auto auto;
  align-items: center;
  font-size: 0.6rem;
  & *:focus {
    outline: none;
    border: none;
  }
`;

const ItemsPerPage = styled.div`
  height: 100%;
  padding: 0 1.5rem;
  display: grid;
  grid-template-columns: 3fr 2fr;
  justify-content: space-between;
  align-items: center;
  > p {
    margin-right: 1rem;
  }
  border-right: 1px solid ${({ theme }) => theme.colors.lightGrey};
`;

const Info = styled.div`
  height: 100%;
  padding: 0 1.5rem;
  border-right: 1px solid ${({ theme }) => theme.colors.lightGrey};
  display: flex;
  align-items: center;
`;

const CaretContainer = styled.div`
  width: 50px;
  border-right: 1px solid ${({ theme }) => theme.colors.lightGrey};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Caret = (Icon) => ({ disabled, onClick }) => {
  const Use = styled(Icon)`
    ${(props) =>
      props.disabled
        ? css`
            & path {
              fill: ${props.theme.colors.lightGrey};
            }
          `
        : css`
            cursor: pointer;
          `}
  `;
  return (
    <CaretContainer>
      <Use disabled={disabled} onClick={!disabled && onClick} />
    </CaretContainer>
  );
};

const Left = Caret(CaretLeft16);
const Right = Caret(CaretRight16);

export { Container, ItemsPerPage, Info, Left, Right };
