import React, { Fragment, useContext } from 'react';
import { InlineNotification } from '../../components';
import { NotificationsContext, RightSideViewContext } from '../../context';
import SideNav from './SideNav';
import {
  BodyContainer,
  ContentContainer,
  RightSideDisplaySplitView,
} from './styles';

const Body = ({ children }) => {
  const { inlineAlert } = useContext(NotificationsContext);
  const { isRightSideViewOpen } = useContext(RightSideViewContext);
  const RightSideComponent = isRightSideViewOpen
    ? RightSideDisplaySplitView
    : Fragment;

  return (
    <BodyContainer>
      <SideNav />
      <ContentContainer padding={!isRightSideViewOpen}>
        {inlineAlert && (
          <InlineNotification
            kind={inlineAlert.type}
            title={inlineAlert.title}
            subtitle={inlineAlert.message}
          />
        )}
        <RightSideComponent
          columnWidths={
            typeof isRightSideViewOpen === 'string' && isRightSideViewOpen
          }
        >
          {children}
        </RightSideComponent>
      </ContentContainer>
    </BodyContainer>
  );
};

export default Body;
