import styled from 'styled-components';

const FilterOptionsContainer = styled.div`
  display: flex;
  align-items: center;
  & .react-datepicker-popper {
    z-index: 1000;
  }
`;

const FilterItem = styled.div`
  width: ${({ width }) => `${width}px`};
  margin-right: 20px;
`;

export { FilterOptionsContainer, FilterItem };
