import { lighten } from 'polished';
import styled from 'styled-components';

const AreaHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & p {
    font-size: 11px;
    color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;
  }

  & p:hover {
    color: ${({ theme }) => theme.colors.trackmaticblue};
  }
`;

const Column = styled.div`
  width: 320px;
  :not(:last-child) {
    margin-right: 50px;
  }
`;

const Container = styled.div`
  display: flex;
  position: relative;
`;

const Heading = styled.h4`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.darkGrey};
`;

const IconContainer = styled.div`
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 10px;
  width: 100%;
  & p {
    font-size: 13px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  color: ${({ theme }) => theme.colors.darkGrey};
  :hover {
    background: ${({ theme }) => theme.colors.mediumGrey};
    color: ${({ theme }) => theme.colors.white};
    cursor: pointer;
    & path {
      fill: ${({ theme }) => theme.colors.white};
    }
  }
  & path {
    fill: ${({ theme, initial }) =>
      initial ? theme.colors.primary : theme.colors.darkGrey};
  }
  & svg {
    margin-right: 10px;
  }
`;

const ItemArea = styled.div`
  height: 300px;
  width: 320px;
  overflow: auto;
  background: ${({ theme }) => theme.colors.lightGrey};
  :hover {
    background: ${({ theme }) => lighten(0.05, theme.colors.lightGrey)};
  }
  margin-top: 10px;
  transition: 300ms;
  > div {
    border-top: 2px solid ${({ theme }) => theme.colors.mediumGrey};
    border-left: 2px solid ${({ theme }) => theme.colors.mediumGrey};
    border-right: 2px solid ${({ theme }) => theme.colors.mediumGrey};
  }
  > div:last-child {
    border-bottom: 2px solid ${({ theme }) => theme.colors.mediumGrey};
  }
`;

export {
  AreaHeader,
  Column,
  Container,
  Heading,
  IconContainer,
  Item,
  ItemArea,
};
