import { Form as FF } from 'formik';
import styled from 'styled-components';

const Form = styled(FF)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default Form;
