import React, { useContext, useEffect, useState } from 'react';
import { PageError, PageLoading, PrintView } from '../../components';
import { apis } from '../../config';
import { getURLParam } from '../../functions';
import { axios } from '../../connections';
import { LoginContext } from '../../context';

const PrintBooking = ({ match }) => {
  const {
    currentEntity: { id: customerId },
  } = useContext(LoginContext);
  const [body, setBody] = useState();
  const [error, setError] = useState();
  const consignmentId = getURLParam(match, 'consignmentId');

  useEffect(() => {
    axios
      .get(apis.getConsignment(customerId, consignmentId))
      .then(({ data }) => {
        const payload = data.data[0].data;
        setBody({
          customerId,
          loadId: payload.load.id,
          consignmentId,
        });
      })
      .catch((e) => {
        setError(e.message);
      });
  }, [customerId, consignmentId]);

  if (error) {
    return <PageError message={error.message} />;
  }

  if (body) {
    return <PrintView apiPath="customer_portal_epod_summary" body={body} />;
  }

  return <PageLoading />;
};

export default PrintBooking;
