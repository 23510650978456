import styled from 'styled-components';
import { EditLink } from '../../styles';
import { ChevronRight16 } from '@carbon/icons-react';

const HeaderContainer = styled.div`
  width: 100%;
  height: ${({ theme }) => theme.headerHeight};
  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  display: grid;
  padding: 0.55rem;
  align-items: center;
  grid-template-columns: auto auto auto 1fr;
`;
const Logo = styled.img`
  height: ${({ theme }) =>
    `${parseInt(theme.headerHeight.replace('px', '')) - 15}px`};
`;

const Logout = styled(EditLink)`
  justify-self: flex-end;
  font-size: 0.9rem;
  color: ${({ theme }) => theme.colors.white};
  :hover {
    color: ${({ theme }) => theme.colors.trackmaticblue};
    font-weight: bold;
  }
`;

const LogoutModalContainer = styled.div`
  width: 100;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
`;

const BreadCrumbsContainer = styled.div`
  display: flex;
`;

const BreadCrumbLink = styled(EditLink)`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.white};
  font-weight: bold;
  :hover {
    color: ${({ theme }) => theme.colors.trackmaticblue};
  }
`;
const BreadCrumbNoLink = styled.div`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.primary};
`;

const BreadCrumbsSeparator = styled(ChevronRight16)`
  margin: 0 1rem;
  & path {
    fill: ${({ theme }) => theme.colors.white};
  }
`;

export {
  HeaderContainer,
  Logo,
  Logout,
  LogoutModalContainer,
  BreadCrumbLink,
  BreadCrumbNoLink,
  BreadCrumbsContainer,
  BreadCrumbsSeparator,
};
