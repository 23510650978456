import { gql } from '@apollo/client';

const GET_CUSTOMERS = gql`
  query getOrgsPaged(
    $take: Int!
    $skip: Int!
    $query: QueryOrgs
    $order: [[String]]
  ) {
    getOrgsPaged(
      pagination: { take: $take, skip: $skip }
      query: $query
      order: $order
    ) {
      data {
        name
        id
        primaryColor
        secondaryColor
        logo
      }
      pagination {
        skip
        take
        total
      }
    }
  }
`;
export { GET_CUSTOMERS };
