import { orderBy } from 'lodash';

const listSortAndFormat = (data = [], label = 'reference', value = 'id') =>
  orderBy(
    data.map((i) => ({
      value: typeof value === 'function' ? value(i) : i[value],
      label: typeof label === 'function' ? label(i) : i[label],
    })),
    ['label'],
    ['asc'],
  );

export default listSortAndFormat;
