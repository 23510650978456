import { useLazyQuery } from '@apollo/client';
import { Field, Formik } from 'formik';
import { PropTypes } from 'prop-types';
import React, { Fragment, useContext } from 'react';
import {
  ActionButtons,
  InlineNotification,
  PasswordFormField,
} from '../../../../components';
import { LoadingContext } from '../../../../context';
import { Form, FormArea, FormItem } from '../../../../styles';
import Banner from '../Banner';
import { LOGIN } from '../LoginForm/gql';
import formikSchema from './formikSchema';
import { setNewPassword } from './functions';

const SetPasswordForm = ({
  session,
  username,
  closeModalAndRedirect,
  setNotify,
}) => {
  const badNotification = (
    <InlineNotification
      kind="error"
      title="Error"
      subtitle="Please make sure that the password meets the requirements"
    />
  );
  const { setLoading } = useContext(LoadingContext);
  const [login] = useLazyQuery(LOGIN, {
    onError: () => {
      setNotify(badNotification);
    },
    onCompleted: (data) => {
      closeModalAndRedirect(data.customerPortalLogin.token);
    },
  });
  return (
    <Formik
      {...formikSchema}
      validateOnMount
      onSubmit={(values) => {
        setLoading(true);
        setNewPassword(session, values.password, username)
          .then(() => {
            setLoading(false);
            setNotify(
              <InlineNotification
                kind="success"
                title="Password set"
                subtitle="Your password has been set"
              />,
            );
            login({ variables: { username, password: values.password } });
          })
          .catch(() => {
            setLoading(false);
            setNotify(badNotification);
          });
      }}
    >
      <Fragment>
        <Banner text="Set Password" />
        <Form style={{ width: '100%', padding: '10px 20px' }}>
          <FormArea
            style={{
              width: '100%',
              padding: '0',
              marginTop: '50px',
              marginBottom: '120px',
            }}
          >
            <FormItem>
              <Field
                labelText="Password"
                placeholder="********"
                name="password"
                component={PasswordFormField}
                light
              />
              <small style={{ fontSize: '10px' }}>
                Password requires at least 1 uppercase letter, lowercase letter,
                <br /> number, and special character
              </small>
            </FormItem>
            <FormItem>
              <Field
                labelText="Confirm Password"
                placeholder="********"
                name="confirmPassword"
                component={PasswordFormField}
                light
              />
            </FormItem>
          </FormArea>
          <ActionButtons primaryName="Set Password" fillWidth noMargin />
        </Form>
      </Fragment>
    </Formik>
  );
};

SetPasswordForm.propTypes = {
  session: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  updateOpen: PropTypes.func.isRequired,
};

export default SetPasswordForm;
